@charset "utf-8";

//area
.profile-map--area {
	padding-bottom: 50%;
}

.area-section {
    width: (343 / 375)*100%;
	max-width: 1120px;
	margin: 0 auto;
	padding-top: 88px;
	&:not(:last-child) {
		margin-bottom: 86px;
	}
	&--first {
		padding-top: 0;
	}
}

.area-box {
	background: #fff;
	padding: 20px;
}

.area-btns-cols {
	display: flex;
    max-width: 750px;
    margin: 60px auto 100px;
	@include sp {
		padding: 30px 0 0;
    	border-top: 1px solid #e0e0e0;
		display: block;
		margin-top: 0;
	}
}

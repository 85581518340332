@charset "utf-8";


//
//便利関数など
//

//デバイス分岐
@mixin pc {
	@media screen and (min-width: ($bp+1) + px) {
		@content;
	}
}
@mixin sp {
	@media screen and (max-width: $bp + px) {
		@content;
	}
}
@mixin tb {
	@media screen and (max-width: 959px) {
		@content;
	}
}

// only mono-device
@mixin only-pc {
	body[class*='pc'] & {
		@content;
	}
}
@mixin only-tab {
	body[class*='tab'] & {
		@content;
	}
}
@mixin only-sp {
	body[class*='sp'] & {
		@content;
	}
}

//（SP用）単位をvwに
@mixin unit-vw($name, $value) {
	#{$name}: ($value / $bp)*100 + vw;
}


//flexbox系
@mixin flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex-wrap($wrap) {
	@if $wrap == wrap {
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	} @else if $wrap == wrap-reverse {
		-webkit-flex-wrap: wrap-reverse;
		-moz-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}
}

@mixin flex-direction($direction,$order) {
	@if $direction == column {
		@if $order == reverse {
			-webkit-box-orient: vertical;
			-webkit-box-direction: reverse;
			-moz-box-orient: vertical;
			-moz-box-direction: reverse;
			-ms-flex-direction: column-reverse;
			-webkit-flex-direction: column-reverse;
			flex-direction: column-reverse;
		} @else {
			-webkit-box-orient: vertical;
			-moz-box-orient: vertical;
			-ms-flex-direction: column;
			-webkit-flex-direction: column;
			flex-direction: column;
		}
	} @else {
		@if $order == reverse {
			-webkit-box-orient: horizontal;
			-webkit-box-direction: reverse;
			-moz-box-orient: horizontal;
			-moz-box-direction: reverse;
			-ms-flex-direction: row-reverse;
			-webkit-flex-direction: row-reverse;
			flex-direction: row-reverse;
		} @else {
			-webkit-box-orient: horizontal;
			-moz-box-orient: horizontal;
			-ms-flex-direction: row;
			-webkit-flex-direction: row;
			flex-direction: row;
		}
	}
}

@mixin flex-justify($justify) {
	@if $justify == start {
		-webkit-box-pack: start;
		-moz-box-pack: start;
		-ms-flex-pack: start;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	} @else if $justify == end {
		-webkit-box-pack: end;
		-moz-box-pack: end;
		-ms-flex-pack: end;
		-webkit-justify-content: flex-end;
		justify-content: flex-end;
	} @else if $justify == center {
		-webkit-box-pack: center;
		-moz-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
	} @else if $justify == between {
		-webkit-box-pack: justify;
		-moz-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}
}

@mixin flex-align($align) {
	@if $align == start {
		-webkit-box-align: start;
		-moz-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
	} @else if $align == end {
		-webkit-box-align: end;
		-moz-box-align: end;
		-ms-flex-align: end;
		-webkit-align-items: flex-end;
		align-items: flex-end;
	} @else if $align == center {
		-webkit-box-align: center;
		-moz-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	} @else if $align == stretch {
		-webkit-box-align: stretch;
		-moz-box-align: stretch;
		-ms-flex-align: stretch;
		-webkit-align-items: stretch;
		align-items: stretch;
	}
}

@mixin flex-grow($grow) {
	-webkit-box-flex: $grow;
	-ms-box-flex: $grow;
	-webkit-flex-grow: $grow;
	flex-grow: $grow;
}

@mixin flex-basis($basis) {
	-webkit-box-flex: $basis;
	-ms-box-flex: $basis;
	-webkit-flex-basis: $basis;
	flex-basis: $basis;
}

@mixin flex-order($order){
	-webkit-box-ordinal-group: $order;
	-ms-flex-order: $order;
	-webkit-order: $order;
	order: $order;
}


//animation
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}
@mixin animation($animation-name) {
	-webkit-animation: $animation-name;
	animation: $animation-name;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}


//ベンダープレフィックス
@mixin PropertySetPrefix($name, $value) {
	@each $prefix in $set-prefix {
		#{$prefix}#{$name}: $value;
	}
}


//border-radius
@mixin border-radius($value) {
	-webkit-border-radius: $value;
	border-radius: $value;
}


//font
@mixin ff-revacs {
	font-family: 'revacs' !important;
	line-height: 1.0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

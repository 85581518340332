@charset "utf-8";

// topics
.topics-contents {
	width: (343 / 375)*100%;
	max-width: 1120px;
	margin: 0 auto 96px;
	padding-top: 88px;
	@include pc {
		display: flex;
		justify-content: space-between;
	}
	&__main {
		@include pc {
			width: (832 / 1120)*100%;
		}
		@include sp {
			margin-bottom: 96px;
		}
		.topics-category-list {
			@include sp {
				overflow-x: auto;
			}
			&__item {
				@include sp {
					flex-shrink: 0;
					width: (100 / 343)*100%;
					text-align: center;
				}
			}
		}
		.article-list {
			margin-bottom: 64px;
		}
	}
	&__side {
		@include pc {
			width: (257 / 1120)*100%;
		}
	}
}
.pagination {
	display: flex;
	justify-content: center;
	&--info {
		@extend .pagination;
	}
	&--csr {
		@extend .pagination;
	}
	&--pressrelease {
		@extend .pagination;
	}
	&--commentary {
		@extend .pagination;
	}
	&--violation {
		@extend .pagination;
	}
	.event-list + & {
		margin-bottom: 64px;
	}
}
.page-numbers {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	margin: 0 2px;
	color: $text-color;
	font-size: 1.6rem;
	&.current {
		background: $blue;
		color: #fff;
	}
	&.prev,
	&.next {
		position: relative;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			@include ff-revacs;
			font-weight: bold;
		}
	}
	&.prev::before {
		content: "\e907";
	}
	&.next::before {
		content: "\e908";
	}
}
.archive-list {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px #e0e0e0 solid;
	border-bottom: 1px #e0e0e0 solid;
	li {
		width: 50%;
		font-size: 1.6rem;
		a {
			position: relative;
			display: inline-block;
			padding: 0.5em 1.5em 0.5em 0;
			color: $text-color;
			&::before {
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -0.5em;
				content: "\e902";
				@include ff-revacs;
			}
		}
	}
}


.entry-header {
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	&__ttl {
		order: 2;
		font-size: 2.8rem;
		font-weight: bold;
	}
	.entry-meta {
		display: flex;
		align-items: center;
		order: 1;
		margin-bottom: 32px;
		&__category {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 116px;
			height: 36px;
			margin-right: 14px;
			background: $blue;
			color: #fff;
			font-size: 1.2em;
		}
		&__date {
			font-size: 1.6rem;
		}
	}
	&__author {
		order: 3;
		font-size: 1.6rem;
	}
}
.entry-body {
	margin-bottom: 64px;
	font-size: 1.6rem;
	word-wrap: break-word;
	word-break: break-word;
	& > *:first-child {
		margin-top: 0;
	}
	p {
		margin-bottom: 1.5em;
	}
	img {
		margin-bottom: 1.5em;
	}
	a {
		color: $blue;
	}
	ul {
		margin-bottom: 1.5em;
		li {
			position: relative;
			padding-left: 1.0em;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				color: $blue;
				content: "●";
			}
		}
	}
	ol {
		margin-bottom: 1.5em;
		counter-reset: entryNumList;
		li {
			position: relative;
			padding-left: 1.5em;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				counter-increment: entryNumList;
				content: counter(entryNumList)'.';
			}
		}
	}
	blockquote {
		margin-bottom: 1.5em;
		padding: 1.5em;
		background: #f5f5f5;
		& > *:last-child {
			margin-bottom: 0;
		}
	}
	.entry-ttl01 {
		margin-top: 78px;
		margin-bottom: 28px;
		padding: 0.75em 0 0.75em 0.5em;
		background: #f5f5f5;
		border-left: 4px $blue solid;
		font-size: 2.2rem;
		font-weight: bold;
	}
	.entry-ttl02 {
		margin-top: 56px;
		margin-bottom: 28px;
		padding-bottom: 12px;
		border-bottom: 2px #333 solid;
		color: #333;
		font-size: 1.8rem;
		font-weight: bold;
	}
	.entry-ttl03 {
		margin-top: 56px;
		color: #333;
		font-size: 1.6rem;
		font-weight: bold;
	}
}
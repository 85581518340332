@charset "utf-8";

// download
.download-list {}
.download-list-item {
	padding: 20px 0;
	border-bottom: 1px #e0e0e0 solid;
	&__ttl {
		padding-left: 1.5em;
		font-size: 1.6rem;
		&::before {
			top: 0.25em;
			left: 0;
			color: $blue;
		}
		& > a {
			color: $text-color;
			@include pc {
				transition: color .2s;
			}
			&:hover {
				@include only-pc {
					color: $blue;
				}
			}
		}
	}
	&__txt {
		padding-left: 1.5em;
		color: #757575;
		font-size: 1.5rem;
	}
}
@charset "utf-8";

// column
.column-section {
	@include pc {
		margin-bottom: 32px;
	}
	&__header {
		background: #d5dae7;
		@include pc {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 28px (32 / 1120)*100%;
		}
		@include sp {
			padding: 32px (16 / 375)*100% 56px;
		}
	}
	&__body {
		background: #f5f5f5;
		@include pc {
			padding: 28px 0 64px;
		}
		@include sp {
			padding: 28px (16 / 375)*100%;
		}
	}
}
.column-author-thumb {
	@include pc {
		width: (135 / 1056)*100%;
	}
	@include sp {
		margin-bottom: 24px;
		text-align: center;
	}
}
.column-author-contents {
	@include pc {
		width: (890 / 1056)*100%;
	}
	&__name {
		position: relative;
		padding-left: 1.5em;
		font-size: 1.8rem;
		font-weight: bold;
		&::before {
			position: absolute;
			top: 0.25em;
			left: 0;
			display: block;
			width: 18px;
			height: 18px;
			background: #424242;
			content: "";
		}
	}
	&__title {
		margin-bottom: 1.5em;
		font-size: 1.6rem;
	}
	&__history {
		font-size: 1.6rem;
	}
}
.column-author-career {
	margin-bottom: 1.5em;
	font-size: 1.6rem;
	@include pc {
		padding: 0 (32 / 1120)*100%;
	}
	&__ttl {
		font-weight: bold;
	}
}
.column-author-career-list {
	&::after {
		display: block;
		content: "など多数";
	}
}
.column-author-book {
	@extend .column-author-career;
	&__ttl {
		@extend .column-author-career__ttl;
	}
}

.column-list {
	border-top: #e0e0e0;
	@include pc {
		padding: 64px (32 / 1120)*100% 0;
	}
}
.column-category-list {
	display: flex;
	border-bottom: 2px $blue solid;
	@include pc {
		margin-bottom: 34px;
	}
	@include sp {
		margin-bottom: 32px;
		overflow-x: auto;
	}
	&__item {
		color: #757575;
		@include pc {
			width: 50%;
			padding: 15px;
			cursor: pointer;
			font-size: 1.8rem;
			text-align: center;
		}
		@include sp {
			-webkit-flex-shrink: 0;
			        flex-shrink: 0;
			width: (214 / 343)*100%;
			padding: 10px 16px;
			font-size: 1.4rem;
		}
		&.is-current {
			background: #d5dae7;
			color: $blue;
			font-weight: bold;
		}
	}
}
.jquery-tab-pager-navi {
	@extend .pagination;
	margin-top: 64px;
	li {
		&.is-after-first {
			position: relative;
			@include pc {
				margin-left: 44px;
			}
			@include sp {
				margin-left: 34px;
			}
			&::before {
				position: absolute;
				top: 0;
				right: 100%;
				display: block;
				margin: 0 2px;
				color: $text-color;
				font-size: 1.6rem;
				content: "…";
				line-height: 40px;
				text-align: center;
				@include pc {
					width: 40px;
					height: 40px;
				}
				@include sp {
					width: 30px;
					height: 30px;
				}
			}
		}
		&.is-before-last {
			position: relative;
			@include pc {
				margin-right: 44px;
			}
			@include sp {
				margin-right: 34px;
			}
			&::after {
				position: absolute;
				top: 0;
				left: 100%;
				display: block;
				margin: 0 2px;
				color: $text-color;
				font-size: 1.6rem;
				content: "…";
				line-height: 40px;
				text-align: center;
				@include pc {
					width: 40px;
					height: 40px;
				}
				@include sp {
					width: 30px;
					height: 30px;
				}
			}
		}
		& > a {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 2px;
			color: $text-color;
			font-size: 1.6rem;
			@include pc {
				width: 40px;
				height: 40px;
			}
			@include sp {
				width: 30px;
				height: 30px;
			}
			&.is-current {
				background: $blue;
				color: #fff;
			}
			&.previos,
			&.next {
				position: relative;
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					@include ff-revacs;
					font-weight: bold;
				}
				&.disable {
					display: none;
				}
			}
			&.previos::before {
				content: "\e907";
			}
			&.next::before {
				content: "\e908";
			}
		}
	}
}
.column-archive-list {
	&--commentary {
		@extend .column-archive-list;
	}
	&--violation {
		@extend .column-archive-list;
	}
	&__link {
		border-bottom: 1px #e0e0e0 solid;
		color: #333;
		font-size: 1.6rem;
		@include pc {
			display: flex;
			padding: 1.5em 0 1.5em 60px;
			transition: background .3s;
		}
		@include sp {
			display: block;
			padding: 1.0em 0 1.0em 52px;
		}
		&:hover {
			@include only-pc {
				background: #f0f0f0;
			}
		}
		&.is-new {
			position: relative;
			&::before {
				position: absolute;
				left: 0;
				color: $blue;
				content: "NEW";
				font-weight: bold;
				@include pc {
					top: 1.5em;
				}
				@include sp {
					top: 1.0em;
				}
			}
		}
		.column-date {
			@include pc {
				margin-right: 24px;
			}
		}
	}
}

.column-contents {
	@extend .topics-contents;
	&--center {
		@extend .topics-contents;
		@include pc {
			justify-content: center;
		}
		.entry-meta__category {
			width: auto;
			margin-right: 0;
			padding: 0 1.0em;
			font-size: 1.6rem;
			font-weight: bold;
		}
	}
	&__main {
		@extend .topics-contents__main;
	}
	&__side {
		@extend .topics-contents__side;
	}
	.entry-meta__category {
		width: auto;
		margin-right: 0;
		padding: 0 1.0em;
		font-size: 1.6rem;
		font-weight: bold;
	}
}

.column-archive-list {
	border-top: 1px #e0e0e0 solid;
	&__item {
		border-bottom: 1px #e0e0e0 solid;
		@include pc {
			cursor: pointer;
		}
		& > span {
			display: block;
			padding: 0.75em 0.5em;
			font-size: 1.6rem;
			&::before {
				top: 50%;
				right: 0.5em;
				margin-top: -0.5em;
				transition: transform .3s;
			}
		}
		&.is-menu-open > span::before {
			transform: rotate(180deg);
		}
		.yearly-archive {
			display: none;
			background: #f5f5f5;
		}
		.yearly-archive-link {
			display: block;
			padding: 12px 24px 16px;
			border-top: 1px #e0e0e0 solid;
			color: $text-color;
			&__date {
				font-size: 1.2rem;
			}
			&__ttl {
				font-size: 1.6rem;
			}
		}
	}
}

.column-prev-next-btn {
	display: flex;
	justify-content: center;
	gap: 1.6rem 5%;
	margin-bottom: 1.6rem;
	@include pc {
		margin-bottom: 32px;
	}
}
.prev-link,
.next-link {
	&:empty {
		display: none;
	}
}
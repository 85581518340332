@charset "utf-8";
// decarbonization
.decarbonization-index {
	@include pc {
		width: 95%;
		max-width: 1120px;
		margin: 0 auto;
	}
}

.decarbonization-index-contents {
	margin-bottom: 88px;
	&:last-of-type {
		margin-bottom: 0;
	}
	&__ttl {
		margin-bottom: 60px;
		font-weight: bold;
		text-align: center;
		@include pc {
			font-size: 3.2rem;
		}
		@include sp {
			font-size: 2.8rem;
		}
	}
	&__intro {
		font-size: 1.6rem;
		text-align: center;
		margin: 0 0 60px;
	}
}

.decarbonization-index-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		position: relative;
		margin-bottom: 32px;
		background: #fff;
		text-align: center;
		&:last-of-type {
			@include sp {
				margin-bottom: 0;
			}
		}
		&.is-disable {
			.decarbonization-link {
				pointer-events: none;
				&::before {
					display: none;
				}
			}
		}
	}
	&--decarbonization {
		@extend .decarbonization-index-list;
		justify-content: space-between;
		.decarbonization-index-list__item {
			@include pc {
				width: (544 / 1120)*100%;
			}
			&:nth-of-type(3n) {
				@include pc {
					margin-right: 0;
				}
			}
		}
		.decarbonization-index-list-header {
			background: #d5dae7;
			@include pc {
				padding-top: 0;
			}
			.decarbonization-caption {
				@include pc {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 4.5em;
					margin-bottom: 4px;
				}
			}
		}
		.decarbonization-meta {
			@include pc {
				width: (272 / 352)*100%;
			}
		}
	}
}

.decarbonization-index-list-header {
	@include pc {
		padding: 28px 0 25px;
	}
	@include sp {
		padding: 20px (24 / 375)*100% 16px;
	}
	.decarbonization-caption {
		color: $blue;
		font-size: 1.8rem;
		font-weight: bold;
		@include pc {
			margin-bottom: 30px;
		}
		@include sp {
			margin-bottom: 20px;
		}
	}
	.decarbonization-meta {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		&__icon {
			flex-shrink: 0;
			width: 64px;
			margin-right: 16px;
		}
		.decarbonization-target-list {
			display: flex;
			flex-wrap: wrap;
			&__item {
				display: block;
				margin-left: 8px;
				margin-bottom: 8px;
				padding: 5px 1.0em;
				background: rgba(#fff, .5);
				border-radius: 4px;
				color: $blue;
				font-size: 1.4rem;
				font-weight: bold;
				text-align: left;
			}
		}
	}
}

.decarbonization-index-list-body {
	background: #fff;
	@include pc {
		padding: 28px (32 / 352)*100% 118px;
	}
	@include sp {
		padding: 20px (24 / 375)*100% 110px;
	}
	.decarbonization-txt {
		margin: 0 auto;
		font-size: 1.6rem;
		text-align: left;
	}
	.decarbonization-link {
		position: absolute;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 56px;
		margin: 0 auto;
		padding: 0 2.0em;
		background: $blue;
		border: 1px $blue solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		@include pc {
			bottom: 32px;
			width: (288 / 352)*100%;
			max-width: 272px;
			transition: background .3s, color .3s;
		}
		@include sp {
			bottom: 24px;
			width: (272 / 295)*100%;
		}
		&::before {
			top: 50%;
			right: 1.0em;
			margin-top: -0.5em;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $blue;
			}
		}
	}
}

.decarbonization-index-list-item {
	margin-bottom: 32px;
	background: #fff;
	@include pc {
		width: (544 / 1120)*100%;
		padding: 28px (32 / 1120)*100% 32px;
	}
	@include sp {
		padding: 20px (23 / 343)*100% 24px;
	}
	&__ttl {
		margin-bottom: 15px;
		font-size: 2.2rem;
		font-weight: bold;
	}
	&__txt {
		font-size: 1.6rem;
		@include pc {
			margin-bottom: 30px;
		}
		@include sp {
			margin-bottom: 22px;
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: (272 / 297)*100%;
		height: 56px;
		margin: 60px auto 0;
		background: $red;
		border: 1px $red solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		@include pc {
			max-width: 314px;
			transition: background .3s, color .3s;
		}
		&::before {
			top: 50%;
			right: 1.0em;
			margin-top: -0.5em;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $red;
			}
		}
	}
}


.decarbonization-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
}
.decarbonization-list-item {
	font-size: 1.6rem;
	@include pc {
		width: (352 / 1120)*100%;
		margin-right: (32 / 1120)*100%;
		margin-bottom: 32px;
	}
	@include sp {
		margin-bottom: 60px;
	}
	&:nth-of-type(3n) {
		@include pc {
			margin-right: 0;
		}
	}
	&__img {
		@include pc {
			margin-bottom: 20px;
		}
		@include sp {
			margin-bottom: 26px;
		}
	}
	&__ttl {
		font-weight: bold;
	}
	&__txt {
		a {
			display: inline-block;
			padding-right: 1.5em;
			color: $blue;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
		}
	}
	&__btn {
		@extend .about-index-list-item__btn;
		margin-top: 30px;
	}
}


.decarbonization-step-list {
	@extend .role-flow-list;
	&::before {
		top: 50px;
		height: calc(100% - 34px);
	}
}
.decarbonization-step-list-item {
	@extend .role-flow-list-item;
	&__ttl {
		@extend .role-flow-list-item__ttl;
	}
}

.decarbonization-img {
	width: 100%;
	margin: 0 auto;
	&--04 {
		max-width: 706px;
	}
	&--05 {
		max-width: 778px;
	}
}

.decarbonization-data-flex-tyu {
	display: flex;
	font-size: 1.4rem;
	line-height: 1.75;
	&__mark {
		width: 4rem;
	}
	&__txt {
		width: calc(100% - 4rem);
	}
}

.decarbonization-data-flex-method {
	display: flex;
	margin-top: 1.5rem;
	@include sp {
		display: block;
	}
	&__term {
		width: 9rem;
	}
	&__contents {
		width: calc(100% - 9rem);
		@include sp {
			width: 100%;
		}
	}
}

.decarbonization-data-sup {
	vertical-align: super;
	font-size: smaller;
}

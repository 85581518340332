@charset "utf-8";


.global-footer {
	position: relative;
	background: #f5f5f5;
	@include pc {
		padding: 128px 0 64px;
	}
	@include sp {
		padding-bottom: 120px;
	}
	.totop {
		display: block;
		padding: 1.5em 0;
		background: #fff;
		color: $blue;
		font-size: 1.4rem;
		font-weight: bold;
		text-align: center;
		@include pc {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 64px;
		}
		span {
			display: inline-block;
			padding-right: 1.5em;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
		}
	}
	&__logo {
		@include pc {
			order: 1;
			width: 50%;
			margin-bottom: 12px;
		}
		@include sp {
			width: (268 / 375)*100%;
			margin: 0 auto 12px;
		}
		& > a {
			display: block;
			@include pc {
				width: 268px;
			}
		}
	}
	&__address {
		color: $text-color;
		font-size: 1.4rem;
		@include pc {
			order: 3;
			width: 50%;
		}
		@include sp {
			margin-bottom: 28px;
			text-align: center;
		}
	}
	&__copyright {
		color: #757575;
		font-size: 1.2rem;
		@include pc {
			order: 4;
			width: 50%;
			text-align: right;
		}
		@include sp {
			text-align: center;
		}
	}
}
.global-footer-inner {
	@include pc {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		flex-wrap: wrap;
		width: (343 / 375)*100%;
		max-width: 1120px;
		margin: 0 auto;
	}
	@include sp {
		padding-top: 32px;
	}
}

.ftr-float-btn {
	position: fixed;
	background: #fff;
	box-shadow: 0 0 4px rgba(#000, .2);
	pointer-events: none;
	opacity: 0;
	transition: bottom .8s, opacity .8s;
	z-index: 100;
	@include pc {
		right: (16 / 375)*100%;
		bottom: 58px;
		height: 60px;
		padding: 6px 54px 6px 30px;
		border-radius: 30px;
	}
	@include sp {
		right: 0;
		bottom: -100px;
		left: 0;
		display: flex;
		flex-wrap: wrap;
		width: (343 / 375)*100%;
		margin: 0 auto;
		padding: 6px (20 / 375)*100% 10px;
		border-radius: 100px;
	}
	&--processing {
		display: flex;
		align-items: center;
		gap: 10px;
		&:before {
			right: 10px;
			font-size: 30px;
			color: $blue;
			top: 50%;
    	transform: translateY(-50%);
		}
	}
	&__txt {
		font-size: 14px;
		font-weight: bold;
		color: $blue;
	}
	&.is-show {
		pointer-events: auto;
		opacity: 1;
		@include pc {
			bottom: 220px;
		}
		@include sp {
			bottom: 120px;
		}
	}
}

.ftr-float-btn-icon {
	&--processing {
		width: 28px;
	}
}

.ftr-contact-btn {
	position: fixed;
	background: #fff;
	box-shadow: 0 0 4px rgba(#000, .2);
	pointer-events: none;
	opacity: 0;
	transition: bottom .8s, opacity .8s;
	z-index: 100;
	@include pc {
		right: (16 / 375)*100%;
		bottom: 58px;
		height: 60px;
		padding: 6px 76px 6px 30px;
		border-radius: 30px;
	}
	@include sp {
		right: 0;
		bottom: -100px;
		left: 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: (343 / 375)*100%;
		margin: 0 auto;
		padding: 6px (10 / 343)*100% 10px;
		border-radius: 12px;
	}
	&__ttl {
		font-size: 1.4rem;
		font-weight: bold;
		@include sp {
			width: 100%;
			margin-bottom: 8px;
			text-align: center;
		}
	}
	&__telnum {
		color: $blue;
		font-size: 2.0rem;
		font-weight: bold;
		@include pc {
			display: block;
			padding-left: 1.5em;
			pointer-events: none;
		}
		@include sp {
			display: flex;
			justify-content: center;
			align-items: center;
			width: (216 / 323)*100%;
			height: 40px;
			background: #f5f5f5;
			border-radius: 20px;
			text-align: center;
		}
		&::before {
			@include pc {
				top: 50%;
				left: 0;
				margin-top: -0.5em;
			}
			@include sp {
				position: static;
				margin-right: 4px;
			}
		}
	}
	&__mail {
		background: $red;
		border: 1px $red solid;
		color: #fff;
		@include pc {
			position: absolute;
			top: 0;
			right: 0;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			transition: background .3s, color .3s;
		}
		@include sp {
			width: (97 / 323)*100%;
			height: 40px;
			border-radius: 20px;
		}
		&::before {
			top: 50%;
			@include pc {
				left: 50%;
				margin: -0.5em 0 0 -0.5em;
				font-size: 3.2rem;
			}
			@include sp {
				left: 0;
				font-size: 2.0rem;
				width: 100%;
				margin-top: -0.5em;
				text-align: center;
			}
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $red;
			}
		}
	}
	&.is-show {
		pointer-events: auto;
		opacity: 1;
		@include pc {
			bottom: 138px;
		}
		@include sp {
			bottom: 16px;
		}
	}
}

.ftr-menu {
	@include pc {
		order: 2;
		width: 50%;
		margin-bottom: 18px;
	}
	@include sp {
		margin-bottom: 28px;
	}
}
.ftr-menu-list {
	display: flex;
	font-size: 1.4rem;
	@include pc {
		justify-content: flex-end;
	}
	@include sp {
		justify-content: center;
	}
	&__item {
		a {
			display: block;
			color: #757575;
			@include pc {
				position: relative;
			}
			&::after {
				@include pc {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background: #757575;
					content: "";
					transform: scaleX(0);
					transition: transform .3s;
				}
			}
			&:hover::after {
				@include only-pc {
					transform: scaleX(1);
				}
			}
		}
	}
	&__item + &__item {
		margin-left: 1.0em;
		padding-left: 1.0em;
		border-left: 1px #757575 solid;
	}
}

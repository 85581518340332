@charset "utf-8";
// service
.service-index {
	@include pc {
		width: 95%;
		max-width: 1120px;
		margin: 0 auto;
	}
}
.service-index-contents {
	margin-bottom: 88px;
	&:last-of-type {
		margin-bottom: 0;
	}
	&__ttl {
		margin-bottom: 60px;
		font-weight: bold;
		text-align: center;
		@include pc {
			font-size: 3.2rem;
		}
		@include sp {
			font-size: 2.8rem;
		}
	}
}
.service-index-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		position: relative;
		margin-bottom: 32px;
		background: #fff;
		text-align: center;
		&:last-of-type {
			@include sp {
				margin-bottom: 0;
			}
		}
		&.is-disable {
			.service-link {
				pointer-events: none;
				&::before {
					display: none;
				}
			}
		}
	}
	&--biomass {
		@extend .service-index-list;
		.service-index-list__item {
			@include pc {
				width: 100%;
			}
		}
		.service-index-list-header {
			background: #d3e7f4;
		}
		.service-meta {
			@include pc {
				width: 350px;
			}
			@include sp {
				width: (272 / 295)*100%;
			}
		}
		.service-txt {
			@include pc {
				width: 480px;
			}
		}
	}
	&--recycle {
		@extend .service-index-list;
		@include pc {
			justify-content: space-between;
		}
		.service-index-list__item {
			@include pc {
				width: (544 / 1120)*100%;
			}
		}
		.service-index-list-header {
			background: #d3e8e6;
		}
		.service-meta {
			@include pc {
				width: 300px;
			}
		}
	}
	&--alliance {
		@extend .service-index-list;
		.service-index-list__item {
			@include pc {
				width: (352 / 1120)*100%;
				margin-right: (32 / 1120)*100%;
			}
			&:nth-of-type(3n) {
				@include pc {
					margin-right: 0;
				}
			}
		}
		.service-index-list-header {
			background: #d5dae7;
			@include pc {
				padding-top: 0;
			}
			.service-caption {
				@include pc {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 4.5em;
					margin-bottom: 4px;
				}
			}
		}
		.service-meta {
			@include pc {
				width: (272 / 352)*100%;
			}
		}
	}
	&--other {
		@extend .service-index-list;
		@include pc {
			justify-content: space-between;
		}
		.service-index-list__item {
			@include pc {
				width: (544 / 1120)*100%;
			}
		}
		.service-index-list-header {
			background: #d5dae7;
		}
		.service-meta {
			@include pc {
				width: (272 / 352)*100%;
			}
		}
	}
}
.service-index-list-header {
	@include pc {
		padding: 28px 0 25px;
	}
	@include sp {
		padding: 20px (24 / 375)*100% 16px;
	}
	.service-caption {
		color: $blue;
		font-size: 1.8rem;
		font-weight: bold;
		@include pc {
			margin-bottom: 30px;
		}
		@include sp {
			margin-bottom: 20px;
		}
	}
	.service-meta {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		&__icon {
			flex-shrink: 0;
			width: 64px;
			margin-right: 16px;
		}
		.service-target-list {
			display: flex;
			flex-wrap: wrap;
			&__item {
				display: block;
				margin-left: 8px;
				margin-bottom: 8px;
				padding: 5px 1.0em;
				background: rgba(#fff, .5);
				border-radius: 4px;
				color: $blue;
				font-size: 1.4rem;
				font-weight: bold;
				text-align: left;
			}
		}
	}
}
.service-index-list-body {
	background: #fff;
	@include pc {
		padding: 28px (32 / 352)*100% 118px;
	}
	@include sp {
		padding: 20px (24 / 375)*100% 110px;
	}
	.service-txt {
		margin: 0 auto;
		font-size: 1.6rem;
		text-align: left;
	}
	.service-link {
		position: absolute;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 56px;
		margin: 0 auto;
		padding: 0 2.0em;
		background: $blue;
		border: 1px $blue solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		@include pc {
			bottom: 32px;
			width: (288 / 352)*100%;
			max-width: 272px;
			transition: background .3s, color .3s;
		}
		@include sp {
			bottom: 24px;
			width: (272 / 295)*100%;
		}
		&::before {
			top: 50%;
			right: 1.0em;
			margin-top: -0.5em;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $blue;
			}
		}
	}
}

.works-link {
	position: relative;
	display: block;
	width: 95%;
	max-width: 1120px;
	margin: 0 auto 96px;
	overflow: hidden;
	&__img {
		img {
			@include pc {
				transition: transform .3s;
			}
		}
	}
	&__txt {
		position: absolute;
		top: 50%;
		left: 50%;
		padding-right: 1.5em;
		color: #fff;
		font-size: 1.8rem;
		font-weight: bold;
		transform: translate(-50%, -50%);
		&::before {
			top: 50%;
			right: 0;
			margin-top: -0.5em;
		}
	}
	&:hover .works-link__img img {
		@include only-pc {
			transform: scale(1.1);
		}
	}
}


.service-contents {
	padding-bottom: 96px;
	&--organic {
		@extend .service-contents;
		.service-contents-header {
			background-image: url(/common/img/service/bg_header-organic.jpg);
		}
		.accepted-object {
			background: #d3e7f4;
		}
	}
	&--drink {
		@extend .service-contents;
		.service-contents-header {
			background-image: url(/common/img/service/bg_header-drink.jpg);
		}
		.accepted-object {
			background: #d3e8e6;
		}
	}
	&--food {
		@extend .service-contents;
		.service-contents-header {
			background-image: url(/common/img/service/bg_header-food.jpg);
		}
		.accepted-object {
			background: #d3e8e6;
		}
	}
	&--alliance {
		@extend .service-contents;
		.service-contents-header {
			background-image: url(/common/img/service/bg_header-alliance.jpg);
		}
		.accepted-object {
			background: #d5dae7;
		}
	}
	&--other {
		@extend .service-contents;
		.service-contents-header {
			background-image: url(/common/img/service/bg_header-other.jpg);
		}
		.accepted-object {
			background: #d5dae7;
		}
	}
	&--processing {
		@extend .service-contents;
		.service-contents-header {
			background-image: url(/common/img/service/bg_header-processing.jpg);
		}
		.accepted-object {
			background: #d3e8e6;
		}
	}
}
.service-contents-header {
	background-repeat: no-repeat;
	background-size: cover;
	@include pc {
		padding: 86px 0 120px;
		background-position: center center;
	}
	@include sp {
		padding: 86px 0 60px;
		background-position: center top;
	}
	&__ttl {
		width: (343 / 375)*100%;
		max-width: 1120px;
		margin: 0 auto 45px;
		color: #fff;
		font-size: 2.8rem;
		font-weight: bold;
		span {
			padding-bottom: 0.1em;
			border-bottom: 8px #fff solid;
			line-height: 2.0;
		}
	}
	&__txt {
		width: (343 / 375)*100%;
		max-width: 1120px;
		margin: 0 auto 1.5em;
		color: #fff;
		font-size: 1.6rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.accepted-object {
	width: 100%;
	max-width: 1120px;
	margin: 0 auto 64px;
	padding: 26px (16 / 375)*100% 24px;
	@include pc {
		margin-top: -64px;
	}
	&__ttl {
		margin-bottom: 28px;
		color: $blue;
		font-size: 2.2rem;
		font-weight: bold;
		text-align: center;
	}
}
.accepted-object-contents {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 544px;
	margin: 0 auto;
	&--chemical {
		@extend .accepted-object-contents;
		@include pc {
			max-width: 326px;
		}
	}
	&--inorganic {
		@extend .accepted-object-contents;
		@include pc {
			max-width: 500px;
		}
	}
	&--industrial {
		@extend .accepted-object-contents;
		@include pc {
			max-width: 500px;
		}
	}
	&--plastic {
		@extend .accepted-object-contents;
		@include pc {
			max-width: 500px;
		}
	}
	&--wood {
		@extend .accepted-object-contents;
		@include pc {
			max-width: 500px;
		}
	}
	&--extermination {
		@extend .accepted-object-contents;
		@include pc {
			max-width: 400px;
		}
	}
	&--transportation {
		@extend .accepted-object-contents;
		@include pc {
			max-width: 600px;
		}
	}
	&__icon {
		width: 64px;
		margin-right: 16px;
		margin-bottom: 8px;
	}
}
.accepted-object-list {
	display: flex;
	flex-wrap: wrap;
	flex: 1 0 0%;
	&__item {
		margin-left: 8px;
		margin-bottom: 8px;
		padding: 5px 1.0em;
		background: rgba(#fff, .5);
		border-radius: 4px;
		color: $blue;
		font-size: 1.4rem;
		font-weight: bold;
	}
}

.models {}
.models-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
}
.models-list-item {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@include pc {
		width: (352 / 1120)*100%;
		margin-right: (32 / 1120)*100%;
		margin-bottom: 32px;
	}
	@include sp {
		margin-bottom: 16px;
	}
	&:nth-of-type(3n) {
		@include pc {
			margin-right: 0;
		}
	}
	&__img {
		flex-shrink: 0;
	}
	sup {
		position: relative;
		top: -1.0em;
		font-size: 1rem;
	}
}
.models-list-item-contents {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex: 1 0 0%;
	background: #f5f5f5;
	@include pc {
		padding: 16px (24 / 352)*100%;
	}
	@include sp {
		padding: 16px (20 / 343)*100%;
	}
	&__ttl {
		font-size: 2.2rem;
	}
	.type-list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		&__item {
			min-width: (144 / 304)*100%;
			margin-top: 16px;
			padding: 0.5em;
			background: #fff;
			border-radius: 4px;
			font-size: 1.6rem;
			text-align: center;
		}
	}
}

.incidental-services-list {
	@include pc {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 (-16 / 1152)*100%;
	}
	&__item {
		padding: 1.0em 0.5em;
		background: #d5dae7;
		color: $blue;
		text-align: center;
		@include pc {
			display: flex;
			justify-content: center;
			align-items: center;
			width: (352 / 1152)*100%;
			margin: 0 (16 / 1152)*100% 32px;
			font-size: 2.2rem;
		}
		@include sp {
			margin-bottom: 16px;
			font-size: 2.1rem;
		}
	}
}

// processing_center
.service-contents--processing {
	.processing-img-col {
		display: flex;
		justify-content: space-between;
		gap: 30px;
		margin: 40px 0 0;
		@include sp {
			flex-direction: column;
		}
		&__item {
			width: 50%;
			@include sp {
				width: 100%;
			}
		}	
	}

	.processing-box {
		display: flex;
		align-items: center;
		background: #f5f5f5;
		justify-content: space-between;
		margin: 40px 0 0;
		&--one-col {
			display: block;
		}
		@include pc {
			margin-bottom: 32px;
			padding: 40px (50 / 1120)*100%;
		}
		@include sp {
			margin-bottom: 16px;
			padding: 20px;
			flex-direction: column;
			gap: 20px;
		}
		&__info {
			max-width: 320px;
			width: 100%;
		}
		&__imgs {
			max-width: 670px;
			width: 100%;
			display: flex;
			align-items: center;
			text-align: center;
			gap: 4%;
			@include sp {
				flex-direction: column;
				gap: 20px;
			}
		}
		&__img {
			width: 48%;
			@include sp {
				width: 100%;
			}
			&--one {
				width: 85%;
				margin: 0 auto;
				@include sp {
					width: 100%;
				}
			}
		}
		&__ttl {
			font-size: 18px;
			color: $blue;
			font-weight: bold;
			margin: 0 0 10px;
		}
		&__txt {
			margin: 10px 0 0;
			font-size: 1.6rem;
		}
	}

}

.processing-table.cmn-table01 {
	white-space: unset;
	@include sp {
		width: 640px;
	}
	th {
		height: 100px;
	}
}
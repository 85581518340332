@charset "utf-8";

//reset css

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-family: inherit;
	font-style: inherit;
	font-weight: inherit;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
html {
	overflow-y: scroll;
}
body {
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: unset;
	line-height: 1;
}
@media all and (-ms-high-contrast:none){
	body {
		font-family: Verdana, Meiryo, sans-serif;
	}
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

a {
	text-decoration: none;
}
img {
	vertical-align: bottom;
	max-width: 100%;
}
article, aside, canvas, details, figcaption, figure,
footer, header, main, menu, nav, section, summary {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
button, input, select, textarea {
	font-family: inherit;
	font-size: 100%;
}
input, textarea {
	margin: 0;
	padding: 0;
	-webkit-appearance: none;
}
nav, ol, ul {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
strong, em, b {
	font-weight: bold;
}

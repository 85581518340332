@charset "utf-8";

// sitemap
.sitemap-list {
	width: (343 / 375)*100%;
	max-width: 1120px;
	margin: 0 auto;
	&__item {
		margin-bottom: 44px;
		@include pc {
			border-bottom: 1px #e0e0e0 solid;
		}
		& > a {
			display: block;
			padding: 14px 24px;
			background: #d5dae7;
			color: $blue;
			font-size: 1.8rem;
			font-weight: bold;
		}
		& > span {
			display: block;
			padding: 14px 24px;
			background: #d5dae7;
			color: $blue;
			font-size: 1.8rem;
			font-weight: bold;
		}
	}
}
.sitemap-under-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		font-size: 1.6rem;
		@include pc {
			width: 50%;
		}
		@include sp {
			border-bottom: 1px #e0e0e0 solid;
		}
		&:nth-of-type(2n-1) {
			@include pc {
				border-right: 1px #e0e0e0 solid;
			}
		}
		& > a {
			display: inline-block;
			padding: 1.0em 0 1.0em 3.5em;
			color: $text-color;
			text-decoration: underline;
			&::before {
				top: 1.25em;
				left: 1.5em;
				color: $blue;
			}
			&:hover {
				@include only-pc {
					text-decoration: none;
				}
			}
		}
	}
}
@charset "utf-8";

html {
	width: auto;
	height: 100%;
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	padding: 0;
}

body {
	position: relative;
	min-width: 320px;
	height: 100%;
	color: #333;
	background: #fff;
	font-family: $base-font;
	line-height: 1.5;
	-webkit-text-size-adjust: none;
}
_:lang(x)::-ms-backdrop, body {
	font-family: "メイリオ", Meiryo, sans-serif;
}

* {
	box-sizing: border-box;
}

.main {
	@media screen and (min-width: 960px) {
		padding-top: 145px;
	}
	@media screen and (max-width: 959px) {
		padding-top: 72px;
	}
}


.is-hidden--pc {
	@include pc {
		display: none;
	}
}
.is-hidden--sp {
	@include sp {
		display: none;
	}
}
.is-hidden--tb {
	@include tb {
		display: none;
	}
}



.is-center {
	text-align: center;
}
.is-right {
	text-align: right;
}


.txt-red {
	color: $red;
}
.txt-blue {
	color: $blue;
}


.num-list {
	counter-reset: numList;
	margin-bottom: 56px;
	&__item {
		margin-bottom: 1.5em;
		font-size: 1.6rem;
		&::before {
			counter-increment: numList;
			content: counter(numList);
			margin-right: 1.5em;
		}
	}
}


.border-box {
	margin-bottom: 24px;
	padding: 20px 24px;
	border: 1px solid;
	&--blue {
		@extend .border-box;
		border-color: $blue;
	}
	& > *:last-child {
		margin-bottom: 0;
	}
}


// web font
[class^="ico_"],
[class*=" ico_"] {
	position: relative;
	font-style: normal;
	&::before {
		position: absolute;
		@include ff-revacs;
	}
}

.ico_biogas-about:before {
  content: "\e915";
}
.ico_canvas:before {
  content: "\e914";
}
.ico_download:before {
  content: "\e912";
}
.ico_file:before {
  content: "\e913";
}
.ico_star:before {
  content: "\e911";
}
.ico_arrow-down:before {
  content: "\e900";
}
.ico_arrow-left:before {
  content: "\e901";
}
.ico_arrow-right:before {
  content: "\e902";
}
.ico_arrow-up:before {
  content: "\e903";
}
.ico_blank:before {
  content: "\e904";
}
.ico_call:before {
  content: "\e905";
}
.ico_chevron-down:before {
  content: "\e906";
}
.ico_chevron-left:before {
  content: "\e907";
}
.ico_chevron-right:before {
  content: "\e908";
}
.ico_chevron-up:before {
  content: "\e909";
}
.ico_column:before {
  content: "\e90a";
}
.ico_description:before {
  content: "\e90b";
}
.ico_faq:before {
  content: "\e90c";
}
.ico_information:before {
  content: "\e90d";
}
.ico_language:before {
  content: "\e90e";
}
.ico_mail:before {
  content: "\e90f";
}
.ico_search:before {
  content: "\e910";
}



// button
.catalog-link,
.prev-link a,
.next-link a {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 56px;
	margin: 0 auto;
	background: $blue;
	border: 1px $blue solid;
	border-radius: 28px;
	color: #fff;
	font-size: 1.6rem;
	@include pc {
		width: 314px;
		transition: background .3s, color .3s;
	}
	@include sp {
		width: 75%;
	}
	&::before {
		top: 50%;
		right: 1.0em;
		margin-top: -0.5em;
	}
	span {
		display: inline-block;
		padding-right: 1.5em;
		&::before {
			top: 50%;
			right: 0;
			margin-top: -0.5em;
		}
	}
	&:hover {
		@include only-pc {
			background: #fff;
			color: $blue;
		}
	}
	&--inq {
		background: $red;
		border: 1px $red solid;
		&:hover {
			@include only-pc {
				color: $red;
			}
		}
	}
}
.prev-link a {
	span {
		padding-right: 0;
		padding-left: 1.5em;
		&::before {
			right: auto;
			left: 0;
		}
	}
}

.catalog-link--area {
	@include sp {
		margin: 20px auto 0;
	}
}


// main-contents
.main-contents {}
.main-contents-header {
	&__ttl {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		background: $blue;
		color: #fff;
		font-weight: bold;
		text-align: center;
		@include pc {
			height: 174px;
		}
		@include sp {
			height: 146px;
		}
		.headline {
			display: block;
			width: 100%;
			@include pc {
				font-size: 3.6rem;
			}
			@include sp {
				font-size: 3.2rem;
			}
		}
		.subheading {
			display: block;
			width: 100%;
			margin-top: 4px;
			@include pc {
				font-size: 2.2rem;
			}
			@include sp {
				font-size: 1.8rem;
			}
		}
	}
}

.breadcrumb {
	@include pc {
		padding: 1.5em 0;
		font-size: 1.2rem;
	}
	@include sp {
		display: none;
	}
}
.breadcrumb-list {
	@include pc {
		display: flex;
		width: (343 / 375)*100%;
		max-width: 1120px;
		margin: 0 auto;
	}
	&__item {
		&::after {
			@include pc {
				margin: 0 8px;
				content: "\e908";
				@include ff-revacs;
				font-size: 1.0rem;
			}
		}
		&:last-of-type {
			&::after {
				@include pc {
					display: none;
				}
			}
		}
		a {
			@include pc {
				color: $text-color;
			}
		}
	}
}

.main-contents-body {
	margin-bottom: 96px;
	padding-top: 88px;
	&--gray {
		@extend .main-contents-body;
		background: #f5f5f5;
		@include pc {
			padding: 96px 0 64px;
		}
		@include sp {
			padding: 96px (16 / 375)*100% 64px;
		}
	}
}


.cmn-section01 {
	width: (343 / 375)*100%;
	max-width: 1120px;
	margin: 0 auto;
	&:not(:last-child) {
		margin-bottom: 86px;
	}
}
.cmn-section02 {
	&:first-of-type {
		margin-top: 62px;
	}
	&:not(:last-child) {
		margin-bottom: 62px;
	}
}
.cmn-section03 {
	&:first-of-type {
		margin-top: 60px;
	}
	&:not(:last-child) {
		margin-bottom: 60px;
	}
}

.cmn-ttl01 {
	margin-bottom: 40px;
	font-size: 2.8rem;
	font-weight: bold;
	span {
		padding-bottom: 0.1em;
		border-bottom: 8px $blue solid;
		line-height: 2.0;
	}
}
.cmn-ttl02 {
	margin-bottom: 32px;
	padding: 12px 0 12px 22px;
	border-left: 1px $blue solid;
	font-size: 2.2rem;
	font-weight: bold;
}
.cmn-ttl03 {
	position: relative;
	margin-bottom: 20px;
	padding-left: 24px;
	font-size: 1.8rem;
	font-weight: bold;
	&::before {
		position: absolute;
		top: 0.25em;
		left: 0;
		width: 18px;
		height: 18px;
		background: #424242;
		content: "";
	}
}
.cmn-ttl04 {
	font-size: 2.2rem;
	font-weight: bold;
	margin-bottom: 20px;
	color: #10328e;
}

.cmn-ttl05 {
	font-size: 1.6rem;
	font-weight: bold;
	margin-bottom: 20px;
}

.cmn-txt {
	margin-bottom: 1.5em;
	font-size: 1.6rem;
	line-height: 1.75;
}

.icon-link {
	display: inline-block;
	padding-right: 1.5em;
	color: $blue;
	&::before {
		top: 50%;
		right: 0;
		margin-top: -0.5em;
	}
	&::after {
		@include pc {
			position: absolute;
			bottom: 0;
			left: 0;
			width: calc(100% - 1.5em);
			height: 1px;
			background: $blue;
			content: "";
			transform: scaleX(0);
			transform-origin: left center;
			transition: transform .3s;
		}
	}
	&:hover::after {
		@include only-pc {
			transform: scaleX(1);
		}
	}
}

.cmn-table01 {
	width: 100%;
	margin-bottom: 32px;
	border: 1px #e0e0e0 solid;
	font-size: 1.6rem;
	th,td {
		padding: 12px 14px;
		border: 1px #e0e0e0 solid;
		text-align: left;
		vertical-align: middle;
	}
	thead {
		th {
			background: #f5f5f5;
		}
	}
	tbody {
		th {
			background: rgba(#10328e, .2);
			font-weight: bold;
		}
	}
	caption {
		caption-side: bottom;
		text-align: right;
		font-size: 1.4rem;
	}
	.bg-rightblue {
		background: rgba(#10328e, .2);
		&--bold {
			font-weight: 700;
		}
	}
	.table-bdrblue {
		&-right {
			border-right: 2px solid #10328e;
		}
		&-top {
			border-top: 2px solid #10328e;
		}
		&-bottom {
			border-bottom: 2px solid #10328e;
		}
		&-left {
			border-left: 2px solid #10328e;
		}
	}
}
.cmn-table02 {
	width: 100%;
	margin-bottom: 32px;
	font-size: 1.6rem;
	th,td {
		padding: 20px 24px;
		text-align: left;
		word-wrap: break-word;
		word-break: break-word;
		@include pc {
			border-top: 1px #e0e0e0 solid;
			border-bottom: 1px #e0e0e0 solid;
		}
		@include sp {
			display: block;
		}
	}
	thead {
		th {
			background: #f5f5f5;
		}
	}
	tbody {
		th {
			background: rgba(#10328e, .2);
			font-weight: bold;
		}
	}
	colgroup {
		@include sp {
			width: 100% !important;
		}
	}
}
.table-wrap {
	&::after {
		@include sp {
			display: block;
			margin-top: 5px;
			content: "※横スクロールでデータを閲覧できます";
			font-size: 1.4rem;
		}
	}
	&__inner {
		@include sp {
			overflow-x: auto;
			&::-webkit-scrollbar {
				height: 10px;
				border-radius: 5px;
			}
			&::-webkit-scrollbar-thumb {
				background: $blue;
				border-radius: 5px;
			}
			&::-webkit-scrollbar-track {
				background: #e0e0e0;
				border-radius: 5px;
			}
		}
	}
	.cmn-table01 {
		@include sp {
			margin-bottom: 16px;
			white-space: nowrap;
		}
	}
}

.table-wrap-pc {
	&::after {
		display: block;
		margin-top: 5px;
		content: "※横スクロールでデータを閲覧できます";
		font-size: 1.4rem;
	}
	&__inner-pc {
		overflow-x: auto;
		&::-webkit-scrollbar {
			height: 10px;
			border-radius: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background: $blue;
			border-radius: 5px;
		}
		&::-webkit-scrollbar-track {
			background: #e0e0e0;
			border-radius: 5px;
		}
		//for firefox
		scrollbar-width: auto;
		scrollbar-color: $blue #e0e0e0;
	}
	.cmn-table01 {
		margin-bottom: 16px;
		white-space: nowrap;
	}
}

.cmn-table-para {
	margin: 0 0 20px;
	@include sp {
		margin: 0 0 10px;
	}
	&:last-child {
		margin: 0;
	}
}

.cmn-table-ttl {
	margin: 30px 0 0;
	font-weight: 700;
	@include sp {
		margin: 20px 0 0;
	}
}


// aside
.aside-contents {}
.other-link {
	background: #f5f5f5;
	font-size: 1.6rem;
	@media screen and (min-width: 960px) {
		display: flex;
		justify-content: center;
		padding: 1.0em 0;
	}
	&__btn {
		display: block;
		color: $blue;
		text-align: center;
		@media screen and (min-width: 960px) {
			width: 20%;
			max-width: 224px;
			padding: 1.0em;
		}
		@media screen and (max-width: 959px) {
			padding: 1.5em 0;
		}
		span {
			position: relative;
			display: inline-block;
			@media screen and (min-width: 960px) {
				padding-right: 1.5em;
			}
			@media screen and (max-width: 959px) {
				width: 12em;
				padding-left: 1.5em;
				text-align: left;
			}
			&::before {
				@media screen and (min-width: 960px) {
					position: static;
					display: block;
					margin-bottom: 8px;
					padding-left: 8px;
					font-size: 2.4rem;
				}
				@media screen and (max-width: 959px) {
					top: 50%;
					left: 0;
					margin-top: -0.5em;
				}
			}
			&::after {
				@include ff-revacs;
				content: "\e902";
				@media screen and (min-width: 960px) {
					position: absolute;
					right: 0;
					top: 32px;
					bottom: 0;
					display: flex;
					align-items: center;
					transition: right .3s;
				}
				@media screen and (max-width: 959px) {
					margin-left: 0.5em;
					line-height: 1.5;
				}
			}
		}
		&:hover span::after {
			@include only-pc {
				right: -10px;
			}
		}
	}
	&__btn + &__btn {
		@media screen and (min-width: 960px) {
			border-left: 1px #e0e0e0 solid;
		}
		@media screen and (max-width: 959px) {
			border-top: 1px #e0e0e0 solid;
		}
	}
}
.bnr-area {
	@include pc {
		padding: 64px 0 32px;
	}
	@include sp {
		padding: 64px (16 / 375)*100% 48px;
	}
}
.bnr-area-list {
	@include pc {
		display: flex;
		justify-content: center;
		width: (343 / 375)*100%;
		max-width: 1120px;
		margin: 0 auto;
	}
	&__link {
		display: block;
		text-align: center;
		@include pc {
			width: (352 / 1120)*100%;
			margin: 0 (16 / 1120)*100% 32px;
		}
		@include sp {
			margin-bottom: 16px;
		}
		&::nth-of-type(3n-2) {
			@include pc {
				margin-left: 0;
			}
		}
	}
}
.conversion-area {
	display: flex;
	&__link {
		width: 50%;
		padding: 2.0em;
		color: #fff;
		font-size: 1.8rem;
		font-weight: bold;
		text-align: center;
		@include pc {
			transition: background .3s, color .3s;
		}
		&--request {
			@extend .conversion-area__link;
			background: $red;
			border: 1px $red solid;
			&:hover {
				@include only-pc {
					background: #fff;
					color: $red;
				}
			}
		}
		&--recruit {
			@extend .conversion-area__link;
			background: #10328e;
			border: 1px #10328e solid;
			&:hover {
				@include only-pc {
					background: #fff;
					color: #10328e;
				}
			}
		}
		span {
			display: inline-block;
			padding-right: 1.5em;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
		}
	}
}



// colorbox
#colorbox,
#cboxOverlay,
#cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	transform: translate3d(0,0,0);
}
#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(#000, .8);
	overflow: hidden;
}
#cboxWrapper {
	max-width: 506px;
}
#cboxMiddleLeft,
#cboxBottomLeft {
	clear: left;
}
#cboxContent {
	position: relative;
	max-width: 506px;
}
#cboxLoadedContent {
	max-width: 506px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
#cboxTitle {
	margin: 0;
}
#cboxLoadingOverlay,
#cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
	@include pc {
		cursor: pointer;
	}
}
#cboxPrevious,
#cboxNext {
	position: absolute;
	top: 50%;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	padding: 0;
	background: none;
	border: none;
	border-bottom: 2px #fff solid;
	border-left: 2px #fff solid;
	outline: 0;
}
#cboxPrevious {
	transform: rotate(45deg);
	@include pc {
		right: calc(100% + 20px);
	}
	@include sp {
		right: calc(100% - 5px);
	}
}
#cboxNext {
	transform: rotate(-135deg);
	@include pc {
		left: calc(100% + 20px);
	}
	@include sp {
		left: calc(100% - 5px);
	}
}
.cboxPhoto {
	float: left;
	margin: auto;
	border: 0;
	display: block;
	max-width: none;
	-ms-interpolation-mode: bicubic;
}
.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0;
	padding: 0;
	margin: 0;
}
#colorbox,
#cboxContent,
#cboxLoadedContent {
	box-sizing: content-box;
}

#colorbox {
	outline: 0;
	@include pc {
		width: 506px !important;
		right: 0 !important;
		left: 0 !important;
		margin: 0 auto !important;
	}
}

#cboxContent {
}

#cboxClose {
	position: absolute;
	width: 48px;
	height: 48px;
	padding: 0;
	margin: 0;
	background: #333;
	border: 0;
	border-radius: 24px;
	overflow: visible;
	@include pc {
		top: 0;
		left: calc(100% + 16px);
	}
	@include sp {
		right: -16px;
		bottom: calc(100% + 16px);
	}
	&::before,
	&::after {
		position: absolute;
		top: 50%;
		left: 10px;
		display: block;
		width: 28px;
		height: 2px;
		margin-top: -1px;
		background: #fff;
		content: "";
	}
	&::before {
		transform: rotate(45deg);
	}
	&::after {
		transform: rotate(-45deg);
	}
	&:active {
		outline: 0;
	}
}

.is-sub {
	font-size: 60%;
}

.is-sup {
	vertical-align: super;
  font-size: smaller;
}

.btn-wrap {
	margin: 60px 0 0;
	text-align: center;
}

.inquiry-btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: (272 / 297)*100%;
	height: 56px;

	background: $red;
	border: 1px $red solid;
	border-radius: 28px;
	color: #fff;
	font-size: 1.6rem;
	@include pc {
		max-width: 314px;
		transition: background .3s, color .3s;
	}
	&::before {
		top: 50%;
		right: 1.0em;
		margin-top: -0.5em;
	}
	&:hover {
		@include only-pc {
			background: #fff;
			color: $red;
		}
	}
}
@charset "utf-8";

// form系
.backnumber-list {
	&__item {
		margin-bottom: 20px;
		padding-bottom: 22px;
		border-bottom: 1px #e0e0e0 solid;
	}
	.backnumber-name {
		font-size: 1.6rem;
		font-weight: bold;
		& > a {
			display: inline-block;
			padding-left: 1.5em;
			color: $blue;
			&::before {
				top: 0.25em;
				left: 0;
			}
		}
	}
	.backnumber-caption {
		font-size: 1.6rem;
	}
	.backnumber-description {
		color: #757575;
		font-size: 1.4rem;
	}
}


.form-list-wrap {
	background: #f5f5f5;
	@include pc {
		padding: 64px (64 / 1120)*100%;
	}
	@include sp {
		padding: 16px (16 / 375)*100%;
	}
}
.form-list-inner {
	background: #fff;
	@include pc {
		padding: 48px (160 / 992)*100%;
	}
	@include sp {
		padding: 32px (16 / 343)*100% 48px;
	}
}
.form-list {
	@include pc {
		margin-bottom: 36px;
	}
	@include sp {
		margin-bottom: 32px;
	}
	.form-txt {
		margin-bottom: 6px;
		font-size: 1.6rem;
	}
	.error {
		display: block;
		margin-top: 0.5em;
		color: $red;
		font-size: 1.4rem;
	}
}

.form-btn {
	@include pc {
		display: flex;
		justify-content: center;
		flex-direction: row-reverse;
	}
	&__comfirm,
	&__send {
		display: block;
		width: 272px;
		height: 56px;
		margin: 0 auto;
		padding: 0;
		background: $blue;
		border: 1px $blue solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		outline: none;
		@include pc {
			cursor: pointer;
			transition: background .3s, color .3s;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $blue;
			}
		}
	}
	&__back {
		display: block;
		width: 272px;
		height: 56px;
		margin: 0 auto;
		padding: 0;
		background: #757575;
		border: 1px #757575 solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		outline: none;
		@include pc {
			cursor: pointer;
			transition: background .3s, color .3s;
		}
		@include sp {
			margin-top: 24px;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: #757575;
			}
		}
	}
}

.form-list-item {
	@include pc {
		display: flex;
		padding: 12px 0;
	}
	@include sp {
		margin-bottom: 16px;
	}
	&__label {
		padding-top: 8px;
		@include pc {
			width: (222 / 672)*100%;
		}
		@include sp {
			margin-bottom: 8px;
		}
		.label-txt {
			font-size: 1.6rem;
			&.is-must {
				&::after {
					display: inline-block;
					margin-left: 8px;
					padding: 0.1em 0.25em;
					background: #d32f2f;
					color: #fff;
					content: "必須";
					font-size: 1.2rem;
				}
			}
		}
		.label-notice {
			color: #757575;
			font-size: 1.4rem;
		}
	}
	&__input {
		@include pc {
			width: (450 / 672)*100%;
		}
		.cmn-txt {
			padding-top: 8px;
		}
	}
}
.input-txt {
	width: 100%;
	padding: 0.5em;
	background: none;
	border: 1px #ccc solid;
	border-radius: 4px;
	font-size: 1.6rem;
	outline: none;
	& + .form-txt {
		margin-top: 10px;
	}
}
textarea.input-txt {
	height: 8.5em;
}
.input-fullname {
	display: flex;
	justify-content: space-between;
	&__lastname,
	&__firstname {
		width: 48%;
		font-size: 1.6rem;
		.input-txt {
			width: calc(100% -1.5em);
		}
	}
}
.input-telnum {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.6rem;
	.input-txt {
		width: 30%;
	}
}

.input-radio-box {
	font-size: 1.6rem;
	@include pc {
		display: flex;
		padding-top: 8px;
	}
	&__choices {
		@include pc {
			margin-right: 20px;
		}
		@include sp {
			display: block;
			margin-bottom: 5px;
		}
	}
	input {
		appearance: radio;
	}
}

.contact-tel-wrap {
	background: #f5f5f5;
	@include pc {
		padding: 64px (64 / 1120)*100%;
	}
	@include sp {
		padding: 16px (16 / 375)*100%;
	}
}
.contact-tel-inner {
	background: #fff;
	text-align: center;
	@include pc {
		padding: 34px 0 48px;
	}
	@include sp {
		padding: 10px 0;
	}
	.tel-num {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		color: $blue;
		&__txt {
			font-weight: bold;
			padding-left: 1.2em;
			@include pc {
				font-size: 4.8rem;
			}
			@include sp {
				font-size: 2.4rem;
			}
			&::before {
				top: 0.25em;
				left: 0;
			}
		}
		&__charge {
			font-weight: bold;
			@include pc {
				font-size: 1.6rem;
			}
			@include sp {
				font-size: 1.0rem;
			}
		}
	}
	.runtime {
		@include pc {
			font-size: 1.6rem;
		}
		@include sp {
			font-size: 1.0rem;
		}
	}
}

.form-top-btn {
	@extend .about-index-list-item__btn;
	margin-top: 30px;
}

#pardot-form {
	label.field-label {
		width: 50%;
		text-align: left;
	}

	p.form-field {
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 700px;
		width: 100%;
		margin: 0 auto 30px;
		flex-wrap: wrap;
	}

	p.form-field.naiyou {
		align-items:baseline;
	}

	.cmn-txt {
		margin: 0 auto 80px;
    text-align: left;
    max-width: 930px; 
	}

	.text,
	.standard {
		width: 50%;
		padding: 0.5em;
		background: none;
		border: 1px solid #ccc;
		border-radius: 4px;
		font-size: 1.6rem;
		outline: none;
	}

	.submit input {
		display: block;
		width: 272px;
		height: 56px;
		margin: 80px auto 0;
		padding: 0;
		background: #10328e;
		border: 1px solid #10328e;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		outline: none;
		cursor: pointer;
		transition: background .3s,color .3s;
		&:hover {
			background: #fff;
    	color: #10328e;
		}
	}

	p.form-field.pd-textarea {
		align-items:baseline;
	}

	.pd-radio input {
		appearance:auto;
		margin: 0 5px 0 0;
	}

	.pd-radio .value {
		width: 50%;
		display: flex;
		flex-wrap: wrap;
	}

	.pd-radio .value span {
		margin: 0 20px 10px 0;
	}

	.pd-radio:after {
		content: none;
	}

	.required .field-label:after {
		display: inline-block;
		margin-left: 8px;
		color: rgb(255, 255, 255);
		content: "必須";
		font-size: 1.2rem;
		padding: 0.1em 0.25em;
		background: rgb(211, 47, 47);
	}

	.error {
		max-width: 700px;
		width: 100%;
		margin: -25px auto 30px;
		font-size: 12px;
		color: rgb(211, 47, 47);
		text-align: right;
	}

	.errors {
		display: none;
	}

	.pardot-form-thanks {
		margin: 0 0 20px;
	}

	.description {
		width: 100%;
    text-align: right;
    font-size: 12px;
    color: #000000;
	}

	@media screen and (max-width:767px) {
		label.field-label {
			width: 100%;
		}
		p.form-field {
				flex-direction: column;
				align-items: baseline;
		}

		.text, .standard {
				width: 100%;
				margin: 10px 0 0;
		}

		/*.mokuteki .value {
				width: 100%;
				margin: 10px 0 0;
		}*/

		.error {
				width: 100%;
		}
		.pd-radio .value {
			width: 100%;
			margin: 10px 0 0;
		}
	}
}
@charset "utf-8";

// company
.company-index-list {
	@extend .about-index-list;
}
.company-index-list-item {
	@extend .about-index-list-item;
	&__ttl {
		@extend .about-index-list-item__ttl;
	}
	&__txt {
		@extend .about-index-list-item__txt;
	}
	&__btn {
		@extend .about-index-list-item__btn;
	}
}


.message-mv {
	width: (343 / 375)*100%;
	max-width: 1120px;
	margin: 0 auto 60px;
	&__img {
		width: 100%;
		@include pc {
			height: 0;
			padding-bottom: (448 / 1120)*100%;
			background: url(/common/img/company/img_message_pc.jpg) no-repeat center top / contain;
		}
	}
}


.guideline-list {}
.guideline-list-item {
	background: #f5f5f5;
	@include pc {
		margin-bottom: 32px;
		padding: 28px (32 / 1120)*100%;
	}
	@include sp {
		margin-bottom: 16px;
		padding: 28px (32 / 343)*100%;
	}
	&__ttl {
		margin-bottom: 15px;
		color: $blue;
		font-size: 22px;
		font-weight: bold;
	}
	&__txt {
		font-size: 16px;
	}
}

.profile-map {
	position: relative;
	width: 100%;
	height: 0;
	margin-bottom: 20px;
	padding-bottom: (450 / 600)*100%;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.group-list {
	&__item {
		a {
			display: inline-block;
			padding-right: 1.5em;
			color: $blue;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
			&::after {
				@include pc {
					position: absolute;
					bottom: 0;
					left: 0;
					width: calc(100% - 1.5em);
					height: 1px;
					background: $blue;
					content: "";
					transform: scaleX(0);
					transform-origin: left center;
					transition: transform .3s;
				}
			}
			&:hover::after {
				@include only-pc {
					transform: scaleX(1);
				}
			}
		}
	}
	&__item + &__item {
		margin-top: 8px;
	}
}

.history-list {
	@extend .role-flow-list;
	&::before {
		top: 50px;
		height: calc(100% - 34px);
	}
}
.history-list-item {
	@extend .role-flow-list-item;
	&__ttl {
		@extend .role-flow-list-item__ttl;
	}
}


.logo-section {
	width: (343 / 375)*100%;
	max-width: 544px;
	margin: 0 auto 86px;
	&__img {
		margin-bottom: 60px;
		text-align: center;
	}
}

.corporate-message {
	background: #f5f5f5;
	@include pc {
		padding: 60px (345 / 1120)*100% 64px;
	}
	@include sp {
		padding: 32px (32 / 375)*100%;
	}
	&__ttl {
		margin-bottom: 15px;
		color: $blue;
		font-size: 22px;
		font-weight: bold;
	}
	.cmn-txt:last-of-type {
		margin-bottom: 0;
	}
}

.organization-table {
	display: table;
	&__tr01 {
		background: $blue;
		th {
			color: #fff;
			background: #10328e;
		}
	}
	&__tr02 {
		th {
			min-width: 6rem;
			&.cel-wide {
				min-width: 10rem;
			}
			span {
				writing-mode: vertical-rl;
				font-weight: normal;
			}
			.num {
				writing-mode: initial;				
			}
		}
	}
	th,
	td {
		text-align: center;
	}
	td {
		padding: 2px 14px;
	}
	sup {
		vertical-align: super;
		font-size: smaller;
	}
}


.organization-table-note-tyu {
	display: flex;
	font-size: 1.4rem;
	line-height: 1.75;
	&__mark {
		margin: 0 1rem 0 0;
	}
}

.organization-ttl-col {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	@include sp {
		display: block;
		margin: 0 0 20px;
	}
	&__btn {
		border: 1px solid $blue;
		color: $blue;
		padding: 5px 10px;
		display: inline-block;
		@include sp {
			width: 100%;
		}
	}
	&__btn-txt {
		font-size: 14px;
		padding: 0 20px 0 0;
		display: block;
		@include sp {
			font-size: 1.2rem;
		}
		&:before {
			top: 50%;
			right: 0;
			margin-top: -0.5em;
		}
	}
}

.organization-list {
	&__item {
		padding: 20px;
		font-size: 16px;
		&:nth-of-type(odd) {
			background: rgba(#10328e, .2);
		}
		&:nth-of-type(even) {
			background: #f5f5f5;
		}
		@include sp {
			padding: 15px;
			font-size: 1.4rem;
		}
	}
	&__link {
		color: $blue;
		text-decoration: underline;
		text-decoration-color: $blue;
	}
	&__name {
		padding: 0 20px 0 0;
		&:before {
			top: 50%;
			right: 0;
			margin-top: -0.5em;
		}
	}
}

.organization-table-note-wrap {
	margin: 20px 0 0;
}

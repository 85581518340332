@charset "UTF-8";


//変数
$bp: 767;
$set-prefix: -webkit-, '';

$blue: #10328e;
$red: #d42d26;
$gray: #333;
$text-color: $gray;

// ベースフォント
$base-font: "-apple-system", 'Noto Sans JP', sans-serif;

@charset "utf-8";

.global-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #fff;
	z-index: 1000;
	@media screen and (max-width: 959px) {
		height: 72px;
		box-shadow: 0 -1px 0 #eee inset;
	}
	&__logo {
		@media screen and (min-width: 960px) {
			width: 95%;
			max-width: 1120px;
			margin: 0 auto;
			padding: 15px 0;
		}
		a {
			position: relative;
			display: block;
			z-index: +1;
			@media screen and (min-width: 960px) {
				width: 268px;
			}
			@media screen and (max-width: 959px) {
				width: 216px;
				padding: 14px 0 14px (16 / 375)*100%;
			}
		}
	}
}

.global-nav {
	&__menu {
		@media screen and (max-width: 959px) {
			display: none;
			background: $gray;
			max-height: calc(100vh - 72px);
			overflow: auto;
		}
	}
}
.global-nav-btn {
	@media screen and (min-width: 960px) {
		display: none;
	}
	@media screen and (max-width: 959px) {
		position: absolute;
		top: 0;
		right: 0;
		width: 72px;
		height: 72px;
		border: none;
		background: transparent;
		outline: none;
		transition: background .3s;
	}
	&__line {
		@media screen and (max-width: 959px) {
			position: absolute;
			top: 50%;
			left: 24px;
			width: 24px;
			height: 3px;
			background: $gray;
			margin-top: -1px;
			transition: background .3s;
		}
		&::before,
		&::after {
			@media screen and (max-width: 959px) {
				position: absolute;
				top: 50%;
				left: 0;
				width: 24px;
				height: 3px;
				background: $gray;
				content: "";
				transition: background .3s, transform .3s;
			}
		}
		&::before {
			@media screen and (max-width: 959px) {
				margin-top: -8px;
			}
		}
		&::after {
			@media screen and (max-width: 959px) {
				margin-top: 5px;
			}
		}
	}
	&.is-open {
		@media screen and (max-width: 959px) {
			background: $gray;
		}
		.global-nav-btn__line {
			@media screen and (max-width: 959px) {
				background: transparent;
			}
			&::before {
				@media screen and (max-width: 959px) {
					margin-top: 0;
					background: #fff;
					transform: rotate(-135deg);
				}
			}
			&::after {
				@media screen and (max-width: 959px) {
					margin-top: 0;
					background: #fff;
					transform: rotate(-45deg);
				}
			}
		}
	}
}


.main-menu {
	@media screen and (min-width: 960px) {
		position: relative;
		background: #fff;
		box-shadow: 0 -1px 0 #eee inset;
	}
}
.main-menu-list {
	@media screen and (min-width: 960px) {
		display: flex;
		justify-content: center;
	}
	&__item {
		@media screen and (min-width: 960px) {
			transition: background .5s;
		}
		& > a {
			display: block;
			font-size: 1.4rem;
			font-weight: bold;
			@media screen and (min-width: 960px) {
				position: relative;
				padding: 1.0em 0;
				color: $text-color;
				transition: color .5s;
			}
			@media screen and (max-width: 959px) {
				padding: 1.0em;
				border-bottom: 1px rgba(#fff, .2) solid;
				color: #fff;
			}
			@media (orientation: landscape) and (max-width: 959px) {
				padding: 1.0em 3.0em;
			}
			span {
				display: block;
				@media screen and (min-width: 960px) {
					padding: .25em 1.0em;
					border-right: 1px $gray solid;
				}
				&.ico_chevron-down {
					@media screen and (min-width: 960px) {
						padding-right: 2.5em;
					}
					&::before {
						top: 50%;
						margin-top: -0.5em;
						@media screen and (min-width: 960px) {
							right: 1.5em;
							font-size: 1.2rem;
						}
						@media screen and (max-width: 959px) {
							right: 0;
						}
					}
				}
			}
		}
		&:hover {
			@include only-pc {
				background: $gray;
			}
			& > a {
				@include only-pc {
					color: #fff;
				}
			}
		}
		&.js-open {
			& > a {
				@media screen and (max-width: 959px) {
					pointer-events: none;
				}
				@include only-tab {
					pointer-events: none;
				}
				span::before {
					@media screen and (max-width: 959px) {
						transition: transform .3s;
					}
				}
			}
		}
		&.is-open {
			@media screen and (min-width: 960px) {
				background: $gray;
			}
			& > a {
				@media screen and (min-width: 960px) {
					color: #fff;
				}
				span::before {
					@media screen and (max-width: 959px) {
						transform: rotate(180deg);
					}
				}
			}
			.sub-nav {
				@media screen and (min-width: 960px) {
					max-height: 234px;
				}
				@media screen and (max-width: 959px) {
					max-height: 700px;
				}
			}
		}
		&:last-of-type {
			& > a span {
				@media screen and (min-width: 960px) {
					border-right: none;
				}
			}
		}
	}
}

.sub-nav {
	max-height: 0;
	overflow: hidden;
	transition: max-height .5s;
	@media screen and (min-width: 960px) {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background: $gray;
	}
	@media screen and (max-width: 959px) {
		background: rgba(#fff, .2);
	}
}
.sub-nav-ttl {
	@media screen and (min-width: 960px) {
		width: 95%;
		max-width: 1120px;
		margin: 0 auto 12px;
		padding-top: 28px;
	}
	&__link {
		color: #fff;
		font-weight: bold;
		@media screen and (min-width: 960px) {
			position: relative;
			display: inline-block;
			padding-right: 1.5em;
			font-size: 1.8rem;
		}
		@media screen and (max-width: 959px) {
			display: block;
			padding: 1.0em;
			font-size: 1.6rem;
			border-bottom: 1px rgba(#fff, .2) solid;
		}
		@media (orientation: landscape) and (max-width: 959px) {
			padding: 1.0em 1.0em 1.0em 3.0em;
		}
		&::before {
			@media screen and (min-width: 960px) {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
			@media screen and (max-width: 959px) {
				display: none;
			}
		}
		&::after {
			@media screen and (min-width: 960px) {
				position: absolute;
				bottom: 0;
				left: 0;
				width: calc(100% - 1.5em);
				height: 1px;
				background: #fff;
				content: "";
				transform: scaleX(0);
				transform-origin: left center;
				transition: transform .3s;
			}
		}
		&:hover::after {
			@include only-pc {
				transform: scaleX(1);
			}
		}
	}
}
.sub-nav-list {
	@media screen and (min-width: 960px) {
		display: flex;
		flex-wrap: wrap;
		width: 95%;
		max-width: 1120px;
		margin: 0 auto;
		padding-bottom: 32px;
	}
	&__item {
		font-size: 1.4rem;
		@media screen and (min-width: 960px) {
			display: flex;
			align-items: center;
			width: (100% / 4);
			min-width: 243px;
			height: 3.0em;
			padding-right: 1.0em;
		}
		& > a {
			padding-right: 1.5em;
			color: #fff;
			font-weight: bold;
			@media screen and (min-width: 960px) {
				position: relative;
				display: inline-block;
			}
			@media screen and (max-width: 959px) {
				display: block;
				padding: 1.0em 2.0em;
				border-bottom: 1px rgba(#fff, .2) solid;
			}
			@media (orientation: landscape) and (max-width: 959px) {
				padding: 1.0em 2.0em 1.0em 4.0em;
			}
			&::before {
				@media screen and (min-width: 960px) {
					top: 50%;
					right: 0;
					margin-top: -0.5em;
				}
				@media screen and (max-width: 959px) {
					display: none;
				}
			}
			&::after {
				@media screen and (min-width: 960px) {
					position: absolute;
					bottom: 0;
					left: 0;
					width: calc(100% - 1.5em);
					height: 1px;
					background: #fff;
					content: "";
					transform: scaleX(0);
					transform-origin: left center;
					transition: transform .3s;
				}
			}
			&:hover::after {
				@include only-pc {
					transform: scaleX(1);
				}
			}
		}
	}
}


.sub-menu {
	@media screen and (min-width: 960px) {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 95%;
		max-width: 1120px;
		margin: 0 auto;
		padding: 22px 0 0;
	}
}
.sub-menu-list {
	display: flex;
	align-items: center;
	@media screen and (min-width: 960px) {
		justify-content: flex-end;
	}
	@media screen and (max-width: 959px) {
		justify-content: center;
		flex-wrap: wrap;
	}
	&__item {
		@media screen and (min-width: 960px) {
			margin-left: 16px;
		}
		@media screen and (max-width: 959px) {
			width: 136px;
			margin: 32px 8px;
		}
		& > a {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 44px;
			border-radius: 22px;
			color: #fff;
			font-size: 1.6rem;
			@media screen and (min-width: 960px) {
				padding: 0 20px;
				background: $blue;
				border: 1px $blue solid;
				transition: background .3s, color .3s;
			}
			@media screen and (max-width: 959px) {
				background: #fff;
				color: $blue;
			}
			&:hover {
				@include only-pc {
					background: #fff;
					color: $blue;
				}
			}
		}
		&--hotline {
			@extend .sub-menu-list__item;
			@media screen and (min-width: 960px) {
				margin-right: 4px;
				margin-left: 0;
			}
			@media screen and (max-width: 959px) {
				display: block;
				width: 100%;
				height: auto;
				margin: 0;
				border-bottom: 1px rgba(#fff, .2) solid;
				border-radius: 0;
			}
			& > a {
				height: auto;
				background: none;
				@media screen and (min-width: 960px) {
					display: inline-block;
					padding-left: 1.5em;
					border: none;
					color: $text-color;
				}
				@media screen and (max-width: 959px) {
					display: block;
					padding: 1.0em 1.0em 1.0em 2.5em;
					color: #fff;
				}
				@media (orientation: landscape) and (max-width: 959px) {
					padding: 1.0em 1.0em 1.0em 4.5em;
				}
				&::before {
					top: 50%;
					margin-top: -0.5em;
					@media screen and (min-width: 960px) {
						left: 0;
					}
					@media screen and (max-width: 959px) {
						left: 1.0em;
					}
				}
				&:hover {
					@include only-pc {
						background: transparent;
						color: $text-color;
					}
				}
			}
		}
	}
	&__item--inq,
	&__item:nth-of-type(2) {
		& > a {
			@media screen and (min-width: 960px) {
				background: $red;
				border: 1px $red solid;
			}
			@media screen and (max-width: 959px) {
				color: $red;
			}
			&:hover {
				@include only-pc {
					background: #fff;
					color: $red;
				}
			}
		}
	}
}


@charset "utf-8";

// csr
.csr-index {
	width: (343 / 375)*100%;
	max-width: 1120px;
	margin: 0 auto;
	&__ttl {
		margin-bottom: 60px;
		font-weight: bold;
		text-align: center;
		@include pc {
			font-size: 3.2rem;
		}
		@include sp {
			font-size: 2.8rem;
		}
	}
}
.csr-page-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
		max-width: 624px;
		margin: 0 auto;
	}
	&__btn {
		@extend .about-index-list-item__btn;
		@include pc {
			max-width: 280px;
			margin: 0 16px 32px;
			padding: 0 2.0em;
			text-align: center;
		}
		@include sp {
			margin-bottom: 16px;
		}
	}
}


.objective-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		margin-bottom: 32px;
		@include pc {
			display: flex;
			flex-direction: column;
			width: (352 / 1120)*100%;
			margin-right: (32 / 1120)*100%;
		}
		&:nth-of-type(3n) {
			@include pc {
				margin-right: 0;
			}
		}
		&--purpose {
			@extend .objective-list__item;
			.objective-contents {
				border-color: $blue;
				color: $blue;
				&::after {
					border-top-color: $blue;
				}
			}
			.objective-result__txt strong {
				color: $blue;
			}
		}
		&--goal {
			@extend .objective-list__item;
			.objective-contents {
				border-color: #37a095;
				color: #37a095;
				&::after {
					border-top-color: #37a095;
				}
			}
			.objective-result__txt strong {
				color: #37a095;
			}
		}
	}
}
.objective-contents {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px (18 / 352)*100%;
	border: 4px solid;
	font-size: 1.6rem;
	text-align: center;
	&::after {
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -20px;
		border-top: 20px solid;
		border-right: 20px transparent solid;
		border-left: 20px transparent solid;
		content: "";
	}
	&__ttl {
		margin-bottom: 8px;
		font-weight: bold;
		span {
			display: block;
			font-size: 12px;
			font-weight: normal;
		}
	}
}
.objective-result {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 35px (18 / 352)*100% 24px;
	background: #f5f5f5;
	font-size: 1.6rem;
	@include pc {
		flex: 1 0 0%;
	}
	&__txt {
		text-align: center;
		strong {
			display: block;
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 2.0;
		}
	}
	&__txt + &__txt {
		margin-top: 24px;
	}
}


.sustainability-block {
	@include pc {
		display: flex;
		justify-content: space-between;
	}
	&__contents {
		@include pc {
			width: (736 / 1120)*100%;
		}
		@include sp {
			margin-bottom: 30px;
		}
	}
	&__img {
		@include pc {
			width: (352 / 1120)*100%;
		}
	}
}
.sustainability-list {
	@include pc {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	&__item {
		margin-bottom: 5px;
		@include pc {
			width: 48%;
		}
		& > a {
			position: relative;
			display: inline-block;
			padding-right: 1.5em;
			color: $blue;
			font-size: 1.6rem;
			&::before {
				@include pc {
					position: absolute;
					bottom: 0;
					left: 0;
					width: calc(100% - 1.5em);
					height: 1px;
					background: $blue;
					content: "";
					transform: scaleX(0);
					transform-origin: left center;
					transition: transform .3s;
				}
			}
			&::after {
				margin-left: 0.5em;
				content: "\e912";
				@include ff-revacs;
			}
			&:hover::before {
				@include only-pc {
					transform: scaleX(1);
				}
			}
		}
	}
}

// food_waste_utilization

.utilization-head {
	color: $blue;
	font-size: 1.8rem;
  font-weight: 700;
  margin: 0 0 10px;
	@include sp {
		font-size: 1.6rem;
  }
}

.utilization-lists {
	&__item {
		margin-bottom: 1.5em;
	}
	&__indent {
		padding-left: 1.6rem;
	}
}

.utilization-img-scheme {
	width: 100%;
	position: relative;
	margin: 0 auto;

	@include sp {
		max-width: none;
  	width: 200%;
	}
	&__link01,
	&__link02,
	&__link03 {
		position: absolute;
		top: 29%;
		height: 13%;
		transition: 0.3s;
		&:hover {
			opacity: 0.6;
		}
	}
	&__link01 {
		width: 6.5%;
    left: 43%;
    top: 29%;
	}
	&__link02 {
		width: 5.5%;
    left: 50.5%;
    top: 29%;
	}
	&__link03 {
		width: 6%;
    left: 36%;
	}
}

.utilization-img {
	margin: 0 auto;
	&--photo {
		margin: 40px auto;
		max-width: 800px;
		@include sp {
			margin: 2rem auto;
		}
	}
	&--ill {
		margin: 80px auto;
		@include sp {
			margin: 4rem auto;
		}
	}
	&--four {
		max-width: 544px;
	}
	&--bio {
		max-width: 580px;
	}
	&--package {
		max-width: 390px;
	}
	&--byproduct {
		max-width: 390px;
	}
	&--feed {
		max-width: 580px;
	}
}

.utilization-img-scroll {
	@include sp {
		overflow-x: scroll;
		padding-bottom: 20px;
		&::-webkit-scrollbar {
			height: 10px;
			border-radius: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background: $blue;
			border-radius: 5px;
		}
		&::-webkit-scrollbar-track {
			background: #e0e0e0;
			border-radius: 5px;
		}
	}
}

.utilization-scheme-modal {
	display: none;
	&__contents {
		background: #fff;
		border-radius: 12px;
		@include pc {
			//width: 506px;
			padding: 32px;
		}
		@include sp {
			padding: 16px;
		}
		.scheme-faci-name {
			margin-bottom: 20px;
			font-size: 1.8rem;
			font-weight: bold;
			&::before {
				margin-right: 0.5em;
				content: "■";
			}
		}
		.scheme-faci-img {
			margin: 0 0 20px;
			&:last-child {
				margin: 0;
			}
			img {
				width: 100%;
			}
		}
		.scheme-faci-img-vir {
			max-width: 332px;
			margin: 0 auto 20px;
			@include sp {
				width: 75%;
			}
			&:last-child {
				margin: 0 auto;
			}
			img {
				width: 100%;
			}
		}
		.scheme-faci-info {
			width: 100%;
			margin-top: 32px;
			th {
				padding: 8px;
				background: rgba(#10328e, .2);
				border-top: 1px #e0e0e0 solid;
				border-bottom: 1px #e0e0e0 solid;
				font-size: 1.4rem;
				text-align: center;
				vertical-align: middle;
				width: 146px;
				@include pc {
					width: 20%;
				}
				@include sp {
					width: 31.5%;
					width: 130px;
				}
			}
			td {
				padding: 8px 16px;
				border-bottom: 1px #e0e0e0 solid;
				font-size: 1.4rem;
			}
		}
	}
}

.utilization-example {
	display: flex;
	align-items: center;
	background: #f5f5f5;
	padding: 20px 40px;
	margin: 0 0 30px;
	@include sp {
		display: block;
		padding: 20px;
		margin: 0 0 3rem;
	}
	&:last-of-type {
		margin: 0 0 80px;
		@include sp {
			margin: 0 0 6rem;
		}
	}
	&__img-wrap {
		width: 11%;
		@include sp {
			width: 35%;
    	margin: 0 auto 2rem;
		}
	}
	&__title {
		font-size: 1.8rem;
    font-weight: 700;
    color: #10328e;
    margin: 0 0 10px;
		@include sp {
			font-size: 1.6rem;
			text-align: center;
		}
	}
	&__contents {
		flex-grow: 1;
		margin: 0 0 0 5%;
		width: 68%;
		@include sp {
			width: 100%;
			margin: 0;
		}
	}
	&__txt {
		margin: 0 0 10px;
	}
}

.utilization-cols {
	display: flex;
	gap: 40px;
	@include sp {
		flex-direction: column;
		gap: 2rem;
	}
	&__txt {
		width: calc(100% - 340px);
		margin-bottom: 0;
		@include sp {
			width: 100%;
		}
	}
	&__img {
		max-width: 300px;
		width: 100%;
		@include sp {
			max-width: none;
			width: 100%;
		}
	}	
}

#colorbox:has(#utilization-scheme-modal01){
	@include pc {
		width: 1000px !important;
	}
	
}

#cboxWrapper:has(#utilization-scheme-modal01){
	max-width: 1000px;
}

#cboxContent:has(#utilization-scheme-modal01){
	max-width: 1000px;
}

#cboxLoadedContent:has(#utilization-scheme-modal01){
	max-width: 1000px;
}


#colorbox:has(#utilization-scheme-modal02){
	@include pc {
		width: 1200px !important;
	}
	
}

#cboxWrapper:has(#utilization-scheme-modal02){
	max-width: 1200px;
}

#cboxContent:has(#utilization-scheme-modal02){
	max-width: 1200px;
}

#cboxLoadedContent:has(#utilization-scheme-modal02){
	max-width: 1200px;
}

.scheme-faci-name {
	margin-bottom: 20px;
	font-size: 1.8rem;
	font-weight: bold;
	&:before {
		margin-right: 0.5em;
		content: "■";
	}
}
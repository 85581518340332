@charset "utf-8";

// event
.event-list {
	margin-bottom: 64px;
	@include pc {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}
.event-list-item {
	background: #f5f5f5;
	@include pc {
		width: (544 / 1120)*100%;
		margin-bottom: 32px;
		padding: 28px (32 / 1120)*100% 32px;
	}
	@include sp {
		margin-bottom: 16px;
		padding: 28px (24 / 343)*100% 32px;
	}
	&__ttl {
		position: relative;
		margin-bottom: 14px;
		font-size: 1.8rem;
		font-weight: bold;
		padding-left: 1.0em;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "■";
		}
	}
	&__date {
		margin-bottom: 24px;
		font-size: 1.6rem;
	}
	&__venue {
		position: relative;
		margin-bottom: 30px;
		padding-left: 4.0em;
		font-size: 1.6rem;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "会場：";
		}
	}
	&__place {
		position: relative;
		margin-bottom: 8px;
		padding-left: 4.0em;
		font-size: 1.6rem;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "場所：";
		}
		.place-site {
			display: block;
			a {
				display: inline-block;
				padding-right: 1.5em;
				color: $blue;
				&::before {
					top: 50%;
					right: 0;
					margin-top: -0.5em;
				}
			}
		}
	}
	&__theme {
		position: relative;
		margin-bottom: 8px;
		padding-left: 4.0em;
		font-size: 1.6rem;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "テーマ：";
		}
	}
	&__lecturer {
		position: relative;
		margin-bottom: 30px;
		padding-left: 4.0em;
		font-size: 1.6rem;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "講師：";
		}
	}
	&__btn {
		@extend .about-index-list-item__btn;
	}
}

.event-contents {
	width: (343 / 375)*100%;
	max-width: 1120px;
	margin: 0 auto 96px;
	padding-top: 88px;
	&__ttl {
		margin-bottom: 28px;
		padding-bottom: 16px;
		border-bottom: 1px $blue solid;
		font-size: 2.8rem;
		font-weight: bold;
	}
	&__editor {
		margin-bottom: 60px;
		font-size: 1.6rem;
	}
	a {
		color: $blue;
	}
	.cmn-table02 {
		tr:last-of-type td {
			@include sp {
				border-bottom: 1px #e0e0e0 solid;
			}
		}
		.place-site {
			display: block;
		}
		a[target="_blank"]::after {
			content: "\e904";
			@include ff-revacs;
		}
	}
}
.event-inquiry {
	padding: 20px 24px;
	border: 1px $blue solid;
	font-size: 1.6rem;
	&__ttl {
		color: $blue;
		font-weight: bold;
	}
}
@charset "utf-8";


// Helvetica
@font-face {
	font-family: "Helvetica Neue";
	src: local("Helvetica Neue Regular");
	font-weight: 100;
}
@font-face {
	font-family: "Helvetica Neue";
	src: local("Helvetica Neue Regular");
	font-weight: 200;
}


//Noto Sans
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese');


//webfont
@font-face {
  font-family: 'revacs';
  src:
    url('/common/fonts/revacs.ttf?2z16fe') format('truetype'),
    url('/common/fonts/revacs.woff?2z16fe') format('woff'),
    url('/common/fonts/revacs.svg?2z16fe#revacs') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
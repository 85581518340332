@charset "utf-8";
// TOP
.mv {
	
}


.pickup-contents {
	background: #f5f5f5;
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
}
.pickup-contents-link {
	display: flex;
	border-bottom: 4px #fff solid;
	@include pc {
		justify-content: center;
		flex-grow: 1;
		min-width: 500px;
		height: 282px;
	}
	@include sp {
		padding: 0 (16 / 375)*100%;
	}
	&__img {
		display: flex;
		align-items: flex-end;
		@include pc {
			width: (298 / 720)*100%;
			max-width: 298px;
			margin-left: (36 / 720)*100%;
		}
		@include sp {
			width: (180 / 343)*100%;
			margin-left: (12 / 343)*100%;
			padding-top: 20px;
		}
	}
	&:hover .pickup-contents-link-meta__btn {
		@include only-pc {
			background: #fff;
			color: $blue;
		}
	}
}
.pickup-contents-link-meta {
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include pc {
		width: (214 / 720)*100%;
		max-width: 220px;
	}
	@include sp {
		width: (148 / 343)*100%;
	}
	&__ttl {
		color: $text-color;
		font-weight: bold;
		line-height: 1.25;
		@include pc {
			font-size: 2.2rem;
		}
		@include sp {
			margin-bottom: 5px;
			font-size: 1.6rem;
		}
		span {
			color: $blue;
			@include pc {
				font-size: 3.2rem;
			}
			@include sp {
				font-size: 2.2rem;
			}
		}
	}
	&__caption {
		color: $text-color;
		@include pc {
			font-size: 1.6rem;
		}
		@include sp {
			margin-bottom: 5px;
			font-size: 1.4rem;
		}
	}
	&__btn {
		display: inline-block;
		height: 44px;
		background: $blue;
		border: 1px $blue solid;
		border-radius: 22px;
		color: #fff;
		@include pc {
			width: 104px;
			margin-top: 28px;
			transition: background .3s, color .3s;
		}
		@include sp {
			width: (104 / 148)*100%;
		}
		&::before {
			top: 50%;
			left: 50%;
			margin: -0.5em 0 0 -0.5em;
			font-size: 1.6rem;
		}
	}
}

.pickup-bnr {
	@include pc {
		padding: 64px 0 48px;
	}
	@include sp {
		padding: 32px (16 / 375)*100% 16px;
	}
}
.pickup-bnr-list {
	@include pc {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		max-width: 1120px;
		margin: 0 auto;
	}
	&__item {
		@include pc {
			width: (352 / 1120)*100%;
			margin-left: (32 / 1120)*100%;
			margin-bottom: 16px;
		}
		@include sp {
			margin-bottom: 16px;
		}
		&:nth-of-type(3n-2) {
			@include pc {
				margin-left: 0;
			}
		}
		img {
			width: 100%;
		}
	}
}


.top-contents {
	padding-top: 88px;
	&--gray {
		@extend .top-contents;
		background: #f5f5f5;
		@include pc {
			padding-bottom: 64px;
		}
		@include sp {
			padding-bottom: 80px;
		}
	}
	&__ttl {
		font-size: 3.2rem;
		font-weight: bold;
		text-align: center;
		@include pc {
			margin-bottom: 60px;
		}
		@include sp {
			margin-bottom: 56px;
		}
	}
}


.top-service-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
		width: (343 / 375)*100%;
		max-width: 1120px;
		margin: 0 auto;
		&--center {
			justify-content: center;
		}
	}
	@include sp {
		padding: 0 (16 / 375)*100%;
	}
}
.top-service-list-link {
	background: #fff;
	@include pc {
		display: block;
		width: (352 / 1120)*100%;
		margin-left: (32 / 1120)*100%;
		margin-bottom: 32px;
		padding: 32px (32 / 1120)*100%;
		text-align: center;
	}
	@include sp {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		padding: 20px (22 / 343)*100% 22px;
	}
	&.is-disable {
		pointer-events: none;
		.top-service-list-link-contents__ttl span {
			padding-right: 0;
			&::after {
				display: none;
			}
		}
	}
	&:nth-of-type(3n-2) {
		@include pc {
			margin-left: 0;
		}
	}
	&:nth-last-of-type(-n+2) {
		.top-service-list-link__icon {
			@include sp {
				width: (92 / 343)*100%;
				margin-right: (8 / 343)*100%;
				margin-left: (-14 / 343)*100%;
			}
		}
	}
	&:hover .top-service-list-link-contents__ttl span::after {
		@include only-pc {
			right: -10px;
		}
	}
	&__icon {
		@include pc {
			height: 64px;
			margin-bottom: 20px;
		}
		@include sp {
			flex-shrink: 0;
			width: (64 / 343)*100%;
			margin-right: (22 / 343)*100%;
		}
		img {
			@include pc {
				height: 100%;
			}
		}
	}
	&--swell {
		@extend .top-service-list-link;
		.top-service-list-link-contents__ttl span::after {
			content: "\e904";
		}
		&:hover .top-service-list-link-contents__ttl span::after {
			@include only-pc {
				right: 0;
			}
		}
	}
}
.top-service-list-link-contents {
	&__ttl {
		margin-bottom: 8px;
		color: $blue;
		font-size: 1.8rem;
		font-weight: bold;
		span {
			@include pc {
				position: relative;
				display: inline-block;
				padding-right: 1.5em;
			}
			&::after {
				@include ff-revacs;
				content: "\e902";
				@include pc {
					position: absolute;
					top: 50%;
					right: 0;
					margin-top: -0.5em;
					transition: right .3s;
				}
				@include sp {
					margin-left: 0.5em;
				}
			}
		}
	}
	&__txt {
		color: $text-color;
		font-size: 1.6rem;
	}
}


.top-about-list {
	@include pc {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}
.top-about-list-link {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	@include pc {
		width: (474 / 1440)*100%;
		height: 256px;
		margin-bottom: 8px;
		overflow: hidden;
	}
	@include sp {
		height: 202px;
		margin-bottom: 16px;
	}
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, .4);
		content: "";
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		@include pc {
			transition: transform .3s;
		}
		&--philosophy {
			@extend .top-about-list-link__img;
			background-image: url(/common/img/top/img_about-philosophy.jpg);
		}
		&--business {
			@extend .top-about-list-link__img;
			background-image: url(/common/img/top/img_about-business.jpg);
		}
		&--role {
			@extend .top-about-list-link__img;
			background-image: url(/common/img/top/img_about-role.jpg);
		}
		&--sdgs {
			@extend .top-about-list-link__img;
			background-image: url(/common/img/top/img_about-sdgs.jpg);
		}
		&--initiatives {
			@extend .top-about-list-link__img;
			background-image: url(/common/img/top/img_about-initiatives.jpg);
		}
		&--facilities {
			@extend .top-about-list-link__img;
			background-image: url(/common/img/top/img_about-facilities.jpg);
		}
		&--ghg-reduction-initiatives {
			@extend .top-about-list-link__img;
			background-image: url(/common/img/top/img_about-ghg-reduction-initiatives.jpg);
		}
	}
	&__txt {
		position: relative;
		display: inline-block;
		padding-right: 1.5em;
		color: #fff;
		font-size: 1.8rem;
		font-weight: bold;
		z-index: +1;
		&::before {
			top: 50%;
			right: 0;
			margin-top: -0.5em;
		}
	}
	&:hover {
		[class^="top-about-list-link__img"] {
			@include only-pc {
				transform: scale(1.1);
			}
		}
	}
}


.top-topics {
	@include pc {
		position: relative;
		width: (343 / 375)*100%;
		max-width: 1120px;
		margin: 0 auto 64px;
	}
	@include sp {
		margin-bottom: 32px;
	}
	.topics-category-list {
		@include sp {
			display: none;
		}
	}
	& + .catalog-link {
		@include pc {
			margin-bottom: 96px;
		}
		@include sp {
			margin-bottom: 48px;
		}
	}
}
.topics-category-list {
	display: flex;
	margin-bottom: 32px;
	border-bottom: 2px $blue solid;
	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: (100% / 4);
		color: #757575;
		font-size: 1.8rem;
		@include pc {
			height: 58px;
			cursor: pointer;
		}
		&.is-current {
			background: #f5f5f5;
			color: $blue;
			font-weight: bold;
		}
	}
}
.topics-box {
	&__ttl {
		margin-bottom: 12px;
		font-size: 2.2rem;
		font-weight: bold;
	}
	&__contents {
		display: none;
		&.is-active {
			display: block;
		}
	}
}

.article-list {
	&--info,
	&--csr,
	&--pressrelease {
		@extend .article-list;
	}
	&__link {
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px #e0e0e0 solid;
		color: $text-color;
		font-size: 1.6rem;
		@include pc {
			padding: 1.0em 0;
			transition: background .3s;
		}
		@include sp {
			flex-wrap: wrap;
			padding: 1.0em;
		}
		&:hover {
			@include only-pc {
				background: #f5f5f5;
			}
		}
	}
	.topics-date {
		flex-shrink: 0;
		width: 8.0em;
		margin-right: 1.0em;
		@include sp {
			margin-bottom: 8px;
		}
	}
	.topics-category {
		flex-shrink: 0;
		width: 116px;
		padding: 0.5em 0;
		background: $blue;
		color: #fff;
		font-size: 1.4rem;
		text-align: center;
		@include pc {
			margin-right: 32px;
		}
		@include sp {
			margin-bottom: 8px;
		}
	}
	.topics-ttl {
		@include pc {
			flex: 1 0 0%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		@include sp {
			width: 100%;
		}
	}
}

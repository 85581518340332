@charset "utf-8";


// faq
.faq-category {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__link {
		display: flex;
		align-items: center;
		height: 56px;
		margin-bottom: 16px;
		background: #f5f5f5;
		border-radius: 28px;
		color: $blue;
		font-size: 1.6rem;
		@include pc {
			width: (352 / 1120)*100%;
			margin-right: (32 / 1120)*100%;
			padding: 0 (40 / 1120)*100%;
			transition: background .3s, color .3s;
		}
		@include sp {
			padding-left: (40 / 343)*100%;
		}
		&:nth-of-type(3n) {
			@include pc {
				margin-right: 0;
			}
		}
		span {
			display: inline-block;
			padding-right: 1.5em;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
		}
		&:hover {
			@include only-pc {
				background: $blue;
				color: #fff;
			}
		}
	}
}

.faq-contents {
	margin-bottom: 32px;
	&__ttl {
		background: #f5f5f5;
		font-size: 1.8rem;
		font-weight: bold;
		@include pc {
			padding: 20px (32 / 1120)*100%;
		}
		@include sp {
			padding: 20px (32 / 343)*100%;
		}
		span {
			display: inline-block;
			padding-left: 1.5em;
			&::before {
				top: 0.25em;
				left: 0;
				color: $blue;
			}
		}
	}
	&__answer {
		border: 1px #e0e0e0 solid;
		font-size: 1.6rem;
		word-wrap: break-word;
		word-break: break-word;
		@include pc {
			padding: 20px (32 / 1120)*100%;
		}
		@include sp {
			padding: 20px (32 / 343)*100%;
		}
		a {
			color: $blue;
		}
	}
}
@charset "utf-8";

// 404
.notfound-contents {
	text-align: center;
	&__ttl {
		margin-bottom: 20px;
		background: url(/common/img/icon_exclamation.jpg) no-repeat center top;
		color: $red;
		font-size: 3.2rem;
		font-weight: bold;
		@include pc {
			padding-top: 130px;
		}
		@include sp {
			padding-top: 90px;
			background-size: 70px auto;
		}
	}
}
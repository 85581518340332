@charset "utf-8";

// about
.about-index-list {
	@include pc {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 95%;
		max-width: 1120px;
		margin: 0 auto;
	}
}
.about-index-list-item {
	margin-bottom: 32px;
	background: #fff;
	@include pc {
		width: (544 / 1120)*100%;
		padding: 28px (32 / 1120)*100% 32px;
	}
	@include sp {
		padding: 20px (23 / 343)*100% 24px;
	}
	&__ttl {
		margin-bottom: 15px;
		font-size: 2.2rem;
		font-weight: bold;
	}
	&__txt {
		font-size: 1.6rem;
		@include pc {
			margin-bottom: 30px;
		}
		@include sp {
			margin-bottom: 22px;
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: (272 / 297)*100%;
		height: 56px;
		margin: 0 auto;
		background: $blue;
		border: 1px $blue solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		@include pc {
			max-width: 272px;
			transition: background .3s, color .3s;
		}
		&::before {
			top: 50%;
			right: 1.0em;
			margin-top: -0.5em;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $blue;
			}
		}
	}
}

.business-section {
	@include pc {
		display: flex;
		align-items: center;
	}
	@include sp {
		margin-bottom: 62px;
	}
	&--reverse {
		@extend .business-section;
		@include pc {
			flex-direction: row-reverse;
		}
	}
	&__ttl {
		position: relative;
		@include pc {
			width: 50%;
		}
		@include sp {
			margin-bottom: 28px;
		}
		.business-img {
			img {
				width: 100%;
			}
		}
		.ttl-txt {
			position: absolute;
			top: 50%;
			right: 0;
			left: 0;
			color: #fff;
			font-size: 2.2rem;
			font-weight: bold;
			text-align: center;
			transform: translateY(-50%);
		}
	}
	&__contents {
		@include pc {
			width: 50%;
			padding: 0 (32 / 1120)*100%;
		}
		.cmn-txt {
			@include pc {
				margin-bottom: 0;
			}
		}
	}
}

.role-menu-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		display: flex;
		align-items: center;
		background: #f5f5f5;
		font-size: 1.6rem;
		font-weight: bold;
		@include pc {
			width: (352 / 1120)*100%;
			margin-right: (32 / 1120)*100%;
			margin-bottom: 32px;
			padding: 20px (24 / 1120)*100% 20px (88 / 1120)*100%;
		}
		@include sp {
			margin-bottom: 16px;
			padding: 20px (24 / 343)*100% 20px (88 / 343)*100%;
		}
		&:nth-of-type(3n) {
			@include pc {
				margin-right: 0;
			}
		}
		&::before {
			top: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #8798c6;
			color: #fff;
			font-size: 4.0rem;
			@include pc {
				width: (72 / 352)*100%;
				font-size: 4.0rem;
			}
			@include sp {
				width: (72 / 343)*100%;
			}
		}
	}
}

.role-flow-list {
	position: relative;
	&::before {
		position: absolute;
		left: 32px;
		display: block;
		width: 4px;
		background: #8798c6;
		content: "";
		z-index: +1;
		@include pc {
			top: 56px;
			height: calc(100% - 40px);
		}
		@include sp {
			top: 92px;
			height: calc(100% - 76px);
		}
	}
	&::after {
		position: absolute;
		left: 28px;
		bottom: -22px;
		display: block;
		border-top: 6px #8798c6 solid;
		border-right: 6px transparent solid;
		border-left: 6px transparent solid;
		content: "";
	}
}
.role-flow-list-item {
	position: relative;
	margin-bottom: 16px;
	padding: 20px (24 / 1120)*100% 20px 68px;
	background: #f5f5f5;
	font-size: 1.6rem;
	&::before {
		position: absolute;
		top: 50%;
		left: 24px;
		display: block;
		width: 12px;
		height: 12px;
		margin-top: -10px;
		background: #fff;
		border: 4px #8798c6 solid;
		border-radius: 50%;
		content: "";
		z-index: +1;
	}
	&__ttl {
		font-weight: bold;
	}
}

.fig-box {
	@include pc {
		display: flex;
	}
	&__contents {
		@include pc {
			flex: 1 0 0%;
		}
		.cmn-txt:last-of-type {
			@include pc {
				margin-bottom: 0;
			}
		}
	}
	&__img {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f5f5f5;
		@include pc {
			margin-left: 32px;
			padding: 40px (24 / 1120)*100%;
		}
		@include sp {
			padding: 40px (24 / 343)*100%;
		}
	}
}

.initiatives-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
}
.initiatives-list-item {
	font-size: 1.6rem;
	@include pc {
		width: (352 / 1120)*100%;
		margin-right: (32 / 1120)*100%;
		margin-bottom: 32px;
	}
	@include sp {
		margin-bottom: 60px;
	}
	&:nth-of-type(3n) {
		@include pc {
			margin-right: 0;
		}
	}
	&__img {
		@include pc {
			margin-bottom: 20px;
		}
		@include sp {
			margin-bottom: 26px;
		}
	}
	&__ttl {
		font-weight: bold;
	}
	&__txt {
		a {
			display: inline-block;
			padding-right: 1.5em;
			color: $blue;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
		}
	}
	&__btn {
		@extend .about-index-list-item__btn;
		margin-top: 30px;
	}
}


.facilities-notice {
	@include pc {
		margin-bottom: 64px;
		padding: 28px 24px;
		border: 1px $blue solid;
	}
	@include sp {
		display: none;
	}
	&__txt {
		@include pc {
			padding: 4px 0 4px 38px;
			background: url(/common/img/about/img_pin00.png) no-repeat left top;
			font-size: 1.6rem;
		}
	}
}

.facilities-contents {}
.facilities-map {
	position: relative;
	@include pc {
		width: 650px;
		margin: 0 auto 64px;
	}
	@include sp {
		margin-bottom: 64px;
	}
	&__pin {
		position: absolute;
		@include pc {
			cursor: pointer;
		}
		@include sp {
			width: (20 / 343)*100%;
		}
		&.pin01 {
			top: (309 / 600)*100%;
			left: (514 / 650)*100%;
		}
		&.pin02 {
			top: (318 / 600)*100%;
			left: (319 / 650)*100%;
		}
		&.pin03 {
			top: (240 / 600)*100%;
			left: (574 / 650)*100%;
		}
		&.pin04 {
			top: (193 / 600)*100%;
			left: (357 / 650)*100%;
		}
		&.pin05 {
			top: (238 / 600)*100%;
			left: (289 / 650)*100%;
		}
		&.pin06 {
			top: (150 / 600)*100%;
			left: (436 / 650)*100%;
		}
		&.pin07 {
			top: (258 / 600)*100%;
			left: (387 / 650)*100%;
		}
		&.pin08 {
			top: (416 / 600)*100%;
			left: (54 / 650)*100%;
		}
		&.pin09 {
			top: (345 / 600)*100%;
			left: (34 / 650)*100%;
		}
		&.pin10 {
			top: (186 / 600)*100%;
			left: (120 / 650)*100%;
		}
		&.pin11 {
			top: (250 / 600)*100%;
			left: (120 / 650)*100%;
		}
		&.pin12 {
			top: (120 / 600)*100%;
			left: (120 / 650)*100%;
		}
	}
}

.facilities-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		position: relative;
		display: flex;
		align-items: center;
		height: 64px;
		margin-bottom: 16px;
		background-color: #f5f5f5;
		background-repeat: no-repeat;
		background-size: auto 32px;
		border-radius: 32px;
		color: $blue;
		font-size: 1.6rem;
		@include pc {
			width: (352 / 1120)*100%;
			margin-right: (32 / 1120)*100%;
			padding-right: (50 / 1120)*100%;
			padding-left: (70 / 1120)*100%;
			background-position: (40 / 352)*100% 50%;
		}
		@include sp {
			padding-right: (50 / 343)*100%;
			padding-left: (70 / 343)*100%;
			background-position: (40 / 343)*100% 50%;
		}
		&::after {
			position: absolute;
			top: 50%;
			right: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 18px;
			height: 15px;
			margin-top: -10px;
			padding-bottom: 3px;
			border: 1px $blue solid;
			border-radius: 10px;
			content: "+";
			font-size: 2.0rem;
		}
		&:hover::after {
			background: $blue;
			color: #fff;
		}
		&.item1 {
			background-image: url(/common/img/about/img_pin01.png);
		}
		&.item2 {
			background-image: url(/common/img/about/img_pin02.png);
		}
		&.item3 {
			background-image: url(/common/img/about/img_pin03.png);
			@include pc {
				margin-right: 0;
			}
		}
		&.item4 {
			background-image: url(/common/img/about/img_pin04.png);
		}
		&.item5 {
			background-image: url(/common/img/about/img_pin05.png);
		}
		&.item6 {
			background-image: url(/common/img/about/img_pin06.png);
			@include pc {
				margin-right: 0;
			}
		}
		&.item7 {
			background-image: url(/common/img/about/img_pin07.png);
		}
		&.item8 {
			background-image: url(/common/img/about/img_pin08.png);
		}
		&.item9 {
			background-image: url(/common/img/about/img_pin09.png);
			@include pc {
				margin-right: 0;
			}
		}
		&.item10 {
			background-image: url(/common/img/about/img_pin10.png);
		}
		&.item11 {
			background-image: url(/common/img/about/img_pin11.png);
		}
		&.item12 {
			background-image: url(/common/img/about/img_pin12.png);
			@include pc {
				margin-right: 0;
			}
		}
	}
}

.facilities-modal {
	display: none;
	&__contents {
		background: #fff;
		border-radius: 12px;
		@include pc {
			width: 506px;
			padding: 32px;
		}
		@include sp {
			padding: 16px;
		}
		.facility-name {
			margin-bottom: 20px;
			font-size: 1.8rem;
			font-weight: bold;
			&::before {
				margin-right: 0.5em;
				content: "■";
			}
		}
		.facility-img {
			img {
				width: 100%;
			}
		}
		.facility-info {
			width: 100%;
			margin-top: 32px;
			th {
				padding: 8px;
				background: rgba(#10328e, .2);
				border-top: 1px #e0e0e0 solid;
				border-bottom: 1px #e0e0e0 solid;
				font-size: 1.4rem;
				text-align: center;
				vertical-align: middle;
				@include pc {
					width: 20%;
				}
				@include sp {
					width: 31.5%;
				}
			}
			td {
				padding: 8px 16px;
				border-bottom: 1px #e0e0e0 solid;
				font-size: 1.4rem;
			}
		}
	}
}


.webcam-wrap {
	background: #f5f5f5;
	text-align: center;
	@include pc {
		padding: 64px;
	}
	@include sp {
		padding: 32px;
	}
}
.webcam-contents {
	@include pc {
		width: 640px;
		margin: 0 auto;
	}
	@include sp {
		width: 100%;
	}
	#camera {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: (480 / 640)*100%;
		@include pc {
			width: 640px;
			margin: 0 auto;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.ghg-chart-wrapper {
	max-width: 930px;
	margin: 0 auto 32px;
	@include sp {
		min-width: 600px;
		margin: 0 auto 16px;
	}
}

.ghg-chart-label-horizon {
	font-size: 1.4rem;
	color: #757575;
	padding: 0 0 0 4%;
	@include sp {
		padding: 0 0 0 5%;
	}
}

.ghg-chart-cap {
	text-align: right;
	font-size: 1.4rem;
}


.ghg-guideline-list-item {
	@extend .guideline-list-item;
	&__ttl {
		@extend .guideline-list-item__ttl;
	}
	&__txt {
		@extend .guideline-list-item__txt;
	}
}


.ghg-initiatives-list {
	@extend .initiatives-list;
}
.ghg-initiatives-list-item {
	@extend .initiatives-list-item;
	&__img {
		@extend .initiatives-list-item__img;
	}
	&__ttl {
		@extend .initiatives-list-item__ttl;
	}
	&__txt {
		a {
			display: inline-block;
			padding-right: 1.5em;
			color: $blue;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
		}
	}
	&__btn {
		@extend .initiatives-list-item__btn;
	}
}

.sdgs-contents-body {
	@include sp {
		margin-bottom: 80px;
	}
	&--gray {
		@include sp {
			margin-bottom: 100px;
		}	
	}
}

.sdgs-index-contents {
	margin-bottom: 88px;
	&:last-of-type {
		margin-bottom: 0;
	}
	&__img {
		max-width: 400px;
		width: 100%;
		margin: 0 auto 90px;
		@include sp {
			max-width: none;
			width: 76%;
			margin: 0 auto 80px;
		}
	}
	&__ttl {
		margin-bottom: 60px;
		font-weight: bold;
		text-align: center;
		@include pc {
			font-size: 3.2rem;
		}
		@include sp {
			font-size: 2.4rem;
		}
	}
	&__intro {
		font-size: 1.6rem;
		text-align: center;
		margin: 0 0 80px;
		@include sp {
			margin: 0 0 60px;
		}
	}
}


.sdgs-acr-bnts {
	display: flex;
	justify-content: center;
	gap: 10px;
	@include tb {
		display: block;
	}
	&__item {
		@include tb {
			margin: 0 0 10px;
			width: 100%;
			display: block;
		}
	}
}

.sdgs-acr-link {
	font-size: 1.4rem;
	color: #10328e;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: #f5f5f5;
	border: 1px solid #f5f5f5;
	border-radius: 50px;
	padding: 10px 40px;
	text-align: center;
	height: 100%;
	width: 100%;
	@include sp {
		padding: 15px 30px;
	}
	&:before {
		top: 50%;
    right: 1em;
    margin-top: -0.5em;
	}
}

.sdgs-box {
	background: #fff;
	margin: 0 0 30px;
	@include pc {
		padding: 28px (32 / 1120)*100% 32px;
	}
	@include sp {
		padding: 20px (23 / 343)*100% 24px;
	}
	&__cont {
		display: flex;
		align-items: center;
    gap: 2%;
    padding: 0 0 30px;
    margin: 0 0 30px;
    border-bottom: 1px solid #E0E0E0;
		@include tb {
			display: block;
		}
	}
	&__img {
		display: flex;
    flex-wrap: wrap;
		align-items: center;
    gap: 10px;
		@include tb {
			width: 100%;
    	gap: 1.5vw 2%;
			margin: 0 0 20px;
		}
	}
	&__pct {
		width: 120px;
		@include tb {
			width: 32%;
		}
	}
	&__txt {
		min-width: calc(98% - 380px);
		font-size: 2.2rem;
		@include tb {
			min-width: auto;
			font-size: 2rem;
		}
	}
	&__comment {
		font-size: 1.6rem;
    line-height: 1.75;
		&:not(:last-child) {
			margin: 0 0 20px;
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: (272 / 297)*100%;
		height: 56px;
		background: $blue;
		border: 1px $blue solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		@include pc {
			max-width: 272px;
			transition: background .3s, color .3s;
		}
		@include sp {
			margin: auto;
		}
		&::before {
			top: 50%;
			right: 1.0em;
			margin-top: -0.5em;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $blue;
			}
		}
	}
}

.sdgs-contents-wrap {
	@include sp {
		width: 100%;
	}
}

.sdgs-anc {
	margin-top: -170px;
	padding-top: 170px;
	@include tb {
		margin-top: -100px;
		padding-top: 100px;
	}
}
@charset "utf-8";
// biogas

.biogas-index {
	@include pc {
		width: 95%;
		max-width: 1120px;
		margin: 0 auto;
	}
}

.biogas-index-contents {
	margin-bottom: 88px;
	&:last-of-type {
		margin-bottom: 0;
	}
	&__ttl {
		margin-bottom: 60px;
		font-weight: bold;
		text-align: center;
		@include pc {
			font-size: 3.2rem;
		}
		@include sp {
			font-size: 2.8rem;
		}
	}
	&__intro {
		font-size: 1.6rem;
		text-align: center;
		margin: 0 0 60px;
	}
}

.biogas-index-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		position: relative;
		margin-bottom: 32px;
		background: #fff;
		text-align: center;
		&:last-of-type {
			@include sp {
				margin-bottom: 0;
			}
		}
		&.is-disable {
			.biogas-link {
				pointer-events: none;
				&::before {
					display: none;
				}
			}
		}
	}
	&--biogas {
		@extend .biogas-index-list;
		justify-content: space-between;
		.biogas-index-list__item {
			@include pc {
				width: (544 / 1120)*100%;
			}
			&:nth-of-type(3n) {
				@include pc {
					margin-right: 0;
				}
			}
		}
		.biogas-index-list-header {
			background: #d5dae7;
			@include pc {
				padding-top: 0;
			}
			.biogas-caption {
				@include pc {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 4.5em;
					margin-bottom: 4px;
				}
			}
		}
		.biogas-meta {
			@include pc {
				width: (272 / 352)*100%;
			}
		}
	}
}

.biogas-index-list-header {
	@include pc {
		padding: 28px 0 25px;
	}
	@include sp {
		padding: 20px (24 / 375)*100% 16px;
	}
	.biogas-caption {
		color: $blue;
		font-size: 1.8rem;
		font-weight: bold;
		@include pc {
			margin-bottom: 30px;
		}
		@include sp {
			margin-bottom: 20px;
		}
	}
	.biogas-meta {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		&__icon {
			flex-shrink: 0;
			width: 64px;
			margin-right: 16px;
		}
		.biogas-target-list {
			display: flex;
			flex-wrap: wrap;
			&__item {
				display: block;
				margin-left: 8px;
				margin-bottom: 8px;
				padding: 5px 1.0em;
				background: rgba(#fff, .5);
				border-radius: 4px;
				color: $blue;
				font-size: 1.4rem;
				font-weight: bold;
				text-align: left;
			}
		}
	}
}

.biogas-index-list-body {
	background: #fff;
	@include pc {
		padding: 28px (32 / 352)*100% 118px;
	}
	@include sp {
		padding: 20px (24 / 375)*100% 110px;
	}
	.biogas-txt {
		margin: 0 auto;
		font-size: 1.6rem;
		text-align: left;
	}
	.biogas-link {
		position: absolute;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 56px;
		margin: 0 auto;
		padding: 0 2.0em;
		background: $blue;
		border: 1px $blue solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		@include pc {
			bottom: 32px;
			width: (288 / 352)*100%;
			max-width: 272px;
			transition: background .3s, color .3s;
		}
		@include sp {
			bottom: 24px;
			width: (272 / 295)*100%;
		}
		&::before {
			top: 50%;
			right: 1.0em;
			margin-top: -0.5em;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $blue;
			}
		}
	}
}

.biogas-index-list-item {
	margin-bottom: 32px;
	background: #fff;
	@include pc {
		width: (544 / 1120)*100%;
		padding: 28px (32 / 1120)*100% 32px;
	}
	@include sp {
		padding: 20px (23 / 343)*100% 24px;
	}
	&__ttl {
		margin-bottom: 15px;
		font-size: 2.2rem;
		font-weight: bold;
	}
	&__txt {
		font-size: 1.6rem;
		@include pc {
			margin-bottom: 30px;
		}
		@include sp {
			margin-bottom: 22px;
		}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: (272 / 297)*100%;
		height: 56px;
		margin: 60px auto 0;
		background: $red;
		border: 1px $red solid;
		border-radius: 28px;
		color: #fff;
		font-size: 1.6rem;
		@include pc {
			max-width: 314px;
			transition: background .3s, color .3s;
		}
		&::before {
			top: 50%;
			right: 1.0em;
			margin-top: -0.5em;
		}
		&:hover {
			@include only-pc {
				background: #fff;
				color: $red;
			}
		}
	}
}


.biogas-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
}
.biogas-list-item {
	font-size: 1.6rem;
	@include pc {
		width: (352 / 1120)*100%;
		margin-right: (32 / 1120)*100%;
		margin-bottom: 32px;
	}
	@include sp {
		margin-bottom: 60px;
	}
	&:nth-of-type(3n) {
		@include pc {
			margin-right: 0;
		}
	}
	&__img {
		@include pc {
			margin-bottom: 20px;
		}
		@include sp {
			margin-bottom: 26px;
		}
	}
	&__ttl {
		font-weight: bold;
	}
	&__txt {
		a {
			display: inline-block;
			padding-right: 1.5em;
			color: $blue;
			&::before {
				top: 50%;
				right: 0;
				margin-top: -0.5em;
			}
		}
	}
	&__btn {
		@extend .about-index-list-item__btn;
		margin-top: 30px;
	}
}


.biogas-step-list {
	@extend .role-flow-list;
	&::before {
		top: 50px;
		height: calc(100% - 34px);
	}
}
.biogas-step-list-item {
	@extend .role-flow-list-item;
	&__ttl {
		@extend .role-flow-list-item__ttl;
	}
}

.biogas-data-flex-tyu {
	display: flex;
	font-size: 1.4rem;
	line-height: 1.75;
	&__mark {
		width: 4rem;
	}
	&__txt {
		width: calc(100% - 4rem);
	}
}

.biogas-data-flex-method {
	display: flex;
	margin-top: 1.5rem;
	@include sp {
		display: block;
	}
	&__term {
		width: 9rem;
	}
	&__contents {
		width: calc(100% - 9rem);
		@include sp {
			width: 100%;
		}
	}
}

.biogas-data-sup {
	vertical-align: super;
	font-size: smaller;
}

.biogas-img {
	width: 100%;
	margin: 0 auto;
	&--01 {
		max-width: 736px;
	}
	&--02 {
		max-width: 544px;
		margin: 0 auto 50px;
	}
	&--03 {
		max-width: 544px;
		margin: 0 auto 50px;
	}
	&--09 {
		max-width: 930px;
		margin: 0 auto 50px;
	}
}

.biogas-box {
	border: $blue solid 4px;
	border-radius: 10px;
	padding: 20px 30px 30px;
	display: flex;
	position: relative;
	margin: 0 0 20px;
	@include sp {
		border: 2px solid #10328e;
    padding: 15px;
	}
	&--last {
		margin: 0 0 86px;
	}
	&:before {
		position: absolute;
		top: 25px;
		left: 0;
		content:"";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 12px 0 12px 16px;
		border-color: transparent transparent transparent $blue;
		@include sp {
			border-width: 6px 0 6px 8px;
		}
	}
	&__img-wrap {
		width: 48px;
		@include sp {
			width: 32px;
		}
	}
	&__cont {
		margin: 0 0 0 20px;
		width: calc(100% - 68px);
		@include sp {
			margin: 0 0 0 15px;
			width: calc(100% - 47px);
		}
	}
	&__title {
		font-size: 2.2rem;
		color: $blue;
		margin: 10px 0 15px;
		font-weight: bold;
		@include sp {
			font-size: 1.6rem;
			margin: 0 0 15px;
		}
	}
	&__txt {
		font-size: 1.6rem;
		color: $blue;
	}
}

.biogas-menu-list {
	@include pc {
		display: flex;
		flex-wrap: wrap;
	}
	&__item {
		display: flex;
		background: #f5f5f5;
		font-size: 1.6rem;
		position: relative;
		align-items: center;
		@include pc {
			width: (544 / 1120)*100%;
			margin-right: (32 / 1120)*100%;
			margin-bottom: 32px;
			padding: 20px (24 / 1120)*100% 20px (100 / 1120)*100%;
		}
		@include sp {
			margin-bottom: 16px;
			padding: 20px (14 / 343)*100% 20px (61 / 343)*100%;
		}
		&:nth-of-type(2n) {
			@include pc {
				margin-right: 0;
			}
		}
		&::before {
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #8798c6;
			color: #fff;
			font-size: 4.0rem;
			@include pc {
				width: (46 / 352)*100%;
				font-size: 4.0rem;
			}
			@include sp {
				width: (48 / 343)*100%;
			}
		}
	}
	&__img {
		position: absolute;
		left: 3%;
    top: 40%;
    width: 7%;
    max-width: 38px;
		@include sp {
			width: 8.5%;
			left: 3%;
			max-width: none;
		}
	}
	&__term {
		font-size: 1.8rem;
		font-weight: bold;
		color: $blue;
		margin: 0 0 10px;
		@include sp {
			font-size: 1.6rem;
		}
	}
}

.biogas-material {
	display: flex;
	align-items: center;
	background: #f5f5f5;
	padding: 20px 50px 0;
	margin: 0 0 30px;
	@include sp {
		display: block;
		padding: 20px;
	}
	&__img-wrap {
		width: 27%;
		margin: auto 0 0;
		@include sp {
			width: 65%;
    	margin: 0 auto 20px;
		}
	}
	&__title {
		font-size: 1.8rem;
    font-weight: 700;
    color: #10328e;
    margin: 0 0 10px;
		@include sp {
			font-size: 1.6rem;
		}
	}
	&__contents {
		flex-grow: 1;
		margin: 0 0 20px 5%;
		width: 68%;
		@include sp {
			width: 100%;
			margin: 0;
		}
	}
	&__txt {
		margin: 0 0 10px;
	}
}

.biogas-target-list {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	&__item {
		display: block;
		padding: 5px 1.0em;
		background: #fff;
		border-radius: 4px;
		color: $blue;
		font-size: 1.4rem;
		font-weight: bold;
		text-align: left;
	}
}

.biogas-acr-bnts {
	display: flex;
	justify-content: center;
	gap: 10px;
	@include tb {
		display: block;
	}
	&__item {
		@include tb {
			margin: 0 0 20px;
			width: 100%;
			display: block;
		}
	}
}

.biogas-acr-link {
	font-size: 1.4rem;
	color: #10328e;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: #f5f5f5;
	border: 1px solid #f5f5f5;
	border-radius: 50px;
	padding: 10px 40px;
	text-align: center;
	height: 100%;
	width: 100%;
	@include sp {
		padding: 10px 30px;
	}
	&:before {
		top: 50%;
    right: 1em;
    margin-top: -0.5em;
	}
}

.biogas-outline,
.biogas-feature,
.biogas-maerial,
.biogas-flow,
.biogas-results,
.biogas-eco {
	margin-top: -200px;
	padding-top: 200px;
	@include sp {
		margin-top: -100px;
		padding-top: 100px;
	}
}

.biogas-lists {
	font-size: 1.6rem;
	margin-bottom: 1.5em;
	&__item {
		padding-left: 1.6rem;
    text-indent: -1.6rem;
    margin: 0 0 10px;
	}
}

//plant

.plant-notice {
	margin-bottom: 64px;
	padding: 28px 20px;
	border: 1px $blue solid;
	@include sp {
		padding: 10px 24px;
	}
	&__txt {
		padding: 4px 0 4px 38px;
		background: url(/common/img/about/img_pin00.png) no-repeat left top;
		font-size: 1.6rem;
		@include sp {
			font-size: 1.4rem;
			background-position-y: center;
		}
	}
}

.plant-img {
	@include pc {
		max-width: 932px;
		width: 100%;
		margin: 0 auto 30px;
	}
	@include sp {
		margin-bottom: 20px;
	}
}

.plant-contents {}
.plant-map {
	position: relative;
	@include pc {
		max-width: 932px;
		width: 100%;
		margin: 0 auto 64px;
	}
	@include sp {
		margin-bottom: 64px;
	}
	&__pin {
		position: absolute;
		@include pc {
			cursor: pointer;
		}
		@include sp {
			width: (20 / 343)*100%;
		}
		&.pin01 {
			top: (438 / 530)*100%;
			left: (589 / 835)*100%;
		}
		&.pin02 {
			top: (318 / 530)*100%;
			left: (382 / 835)*100%;
		}
		&.pin03 {
			top: (278 / 530)*100%;
			left: (150 / 835)*100%;
		}
		&.pin04 {
			top: (258 / 530)*100%;
			left: (33 / 835)*100%;
		}
		&.pin05 {
			top: (41 / 530)*100%;
			left: (91 / 835)*100%;
		}
		&.pin06 {
			top: (41 / 530)*100%;
			left: (340 / 835)*100%;
		}
		&.pin07 {
			top: (15 / 530)*100%;
			left: (556 / 835)*100%;
		}
		&.pin08 {
			top: (98 / 530)*100%;
			left: (693 / 835)*100%;
		}
		&.pin09 {
			top: (123 / 530)*100%;
			left: (780 / 835)*100%;
		}
		&.pin10 {
			top: (175 / 530)*100%;
			left: (780 / 835)*100%;
		}
		&.pin11 {
			top: (299 / 530)*100%;
			left: (33 / 835)*100%;
		}
		&.pin12 {
			top: (258 / 530)*100%;
			left: (265 / 835)*100%;
		}
		&.pin13 {
			top: (366 / 530)*100%;
			left: (564 / 835)*100%;
		}
		&.pin14 {
			top: (216 / 530)*100%;
			left: (481 / 835)*100%;
		}
	}
}

.plant-faci-txt {
	font-size: 1.6rem;
}

.plant-list,
.plant-list02 {
	@include pc {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 40px;
	}
	@include sp {
		margin: 0 0 20px;
	}
	&__item {
		position: relative;
		display: flex;
		align-items: center;
		height: 64px;
		margin-bottom: 16px;
		background-color: #f5f5f5;
		background-repeat: no-repeat;
		background-size: auto 32px;
		border-radius: 32px;
		color: $blue;
		font-size: 1.6rem;
		@include pc {
			width: (352 / 1120)*100%;
			margin-right: (32 / 1120)*100%;
			padding-right: (50 / 1120)*100%;
			padding-left: (70 / 1120)*100%;
			background-position: (40 / 352)*100% 50%;
		}
		@include sp {
			padding-right: (50 / 343)*100%;
			padding-left: (70 / 343)*100%;
			background-position: (40 / 343)*100% 50%;
		}
		&::after {
			position: absolute;
			top: 50%;
			right: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 18px;
			height: 15px;
			margin-top: -10px;
			padding-bottom: 3px;
			border: 1px $blue solid;
			border-radius: 10px;
			content: "+";
			font-size: 2.0rem;
		}
		&:hover::after {
			background: $blue;
			color: #fff;
		}
		&.item1 {
			background-image: url(/common/img/about/img_pin01.png);
		}
		&.item2 {
			background-image: url(/common/img/about/img_pin02.png);
		}
		&.item3 {
			background-image: url(/common/img/about/img_pin03.png);
			@include pc {
				margin-right: 0;
			}
		}
		&.item4 {
			background-image: url(/common/img/about/img_pin04.png);
		}
		&.item5 {
			background-image: url(/common/img/about/img_pin05.png);
		}
		&.item6 {
			background-image: url(/common/img/about/img_pin06.png);
			@include pc {
				margin-right: 0;
			}
		}
		&.item7 {
			background-image: url(/common/img/about/img_pin07.png);
		}
		&.item8 {
			background-image: url(/common/img/about/img_pin08.png);
		}
		&.item9 {
			background-image: url(/common/img/about/img_pin09.png);
			@include pc {
				margin-right: 0;
			}
		}
		&.item10 {
			background-image: url(/common/img/about/img_pin10.png);
		}
		&.item11 {
			background-image: url(/common/img/about/img_pin01.png);
		}
		&.item12 {
			background-image: url(/common/img/about/img_pin02.png);
		}
		&.item13 {
			background-image: url(/common/img/about/img_pin03.png);
			@include pc {
				margin-right: 0;
			}
		}
		&.item14 {
			background-image: url(/common/img/about/img_pin04.png);
		}
	}
}

.plant-modal {
	display: none;
	&__contents {
		background: #fff;
		border-radius: 12px;
		@include pc {
			width: 506px;
			padding: 32px;
		}
		@include sp {
			padding: 16px;
		}
		.plant-faci-name {
			margin-bottom: 20px;
			font-size: 1.8rem;
			font-weight: bold;
			&::before {
				margin-right: 0.5em;
				content: "■";
			}
		}
		.plant-faci-img {
			margin: 0 0 20px;
			&:last-child {
				margin: 0;
			}
			img {
				width: 100%;
			}
		}
		.plant-faci-img-vir {
			max-width: 332px;
			margin: 0 auto 20px;
			@include sp {
				width: 75%;
			}
			&:last-child {
				margin: 0 auto;
			}
			img {
				width: 100%;
			}
		}
		.plant-faci-info {
			width: 100%;
			margin-top: 32px;
			th {
				padding: 8px;
				background: rgba(#10328e, .2);
				border-top: 1px #e0e0e0 solid;
				border-bottom: 1px #e0e0e0 solid;
				font-size: 1.4rem;
				text-align: center;
				vertical-align: middle;
				width: 146px;
				@include pc {
					width: 20%;
				}
				@include sp {
					width: 31.5%;
					width: 130px;
				}
			}
			td {
				padding: 8px 16px;
				border-bottom: 1px #e0e0e0 solid;
				font-size: 1.4rem;
			}
		}
	}
}

.plant-table {
	.plant-table__cap {
		caption-side: top;
		text-align: left;
		font-size: 1.6rem;
		font-weight: bold;
		margin: 0 0 5px;
	}
	.plant-table__th,
	.plant-table__td {
		padding: 20px;
	}
	.plant-table__th {
		width: 146px;
		@include sp {
			width: 130px;
		}
	}
}

.plant-guideline-list-item {
	background: #f5f5f5;
	@include pc {
		margin-bottom: 32px;
		padding: 28px (32 / 1120)*100%;
	}
	@include sp {
		margin-bottom: 16px;
		padding: 28px (32 / 343)*100%;
	}
	&__ttl {
		margin-bottom: 15px;
		color: $blue;
		font-size: 22px;
		font-weight: bold;
	}
	&__txt {
		margin-bottom: 15px;
		font-size: 16px;
	}
	&__img {
		margin-bottom: 15px;
		text-align: center;
	}
}

.plant-guideline-list-item-col2 {
	@include pc {
		display: flex;
		gap: 0 5%;
	}
	&__body {
		@include sp {
			margin-bottom: 1.6rem;
		}
		@include pc {
			width: 70%;
		}
	}
	&__pic {
		@include sp {
			margin-bottom: 1.6rem;
		}
		@include pc {
			width: 25%;
		}
	}
}

.plant-result-wrap {
	text-align: center;
}

.plant-result {
	display: inline-flex;
	background: url(/common/img/biogas/img_plant07.png);
	background-repeat: no-repeat;
	justify-content: center;
	background-size: contain;
	padding: 0 0 100px;
	background-position: center;
	min-width: 670px;
	@include sp {
		flex-direction: column;
    align-items: center;
    gap: 50px;
		padding: 0;
		background: url(/common/img/biogas/img_plant07_sp.png);
		background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
		padding: 40px 0;
		min-width: 260px;
	}
	&__ttl {
		font-size: 2rem;
		margin: 0 0 20px;
		@include sp {
			font-size: 1.6rem;
			margin: 0 0 10px;
		}
		span {
			border-bottom: 6px solid $blue;
			@include sp {
				border-bottom: 4px solid $blue;
			}
		}
	}
	&__item {
		text-align: center;
		word-break: break-all;
		&--arrow {
			width: 80px;
			margin: 0 40px 0 15px;
			padding: 190px 0 0;
			@include sp {
				margin: 0;
				padding: 0;
				transform: rotate(90deg);
				width: 60px;
			}
		}
	}
	&__img {
		max-width: 112px;
		margin: 0 0 25px;
		@include sp {
			max-width: 72px;
			margin: 0 0 10px;
		}
	}
	&__num {
		font-size: 48px;
		font-weight: bold;
		@include sp {
			font-size: 3.6rem;
		}
	}
	&__num-txt {
		font-size: 14px;
		@include sp {
			font-size: 1.4rem;
		}
	}
	&__tyu {
		font-size: 13px;
		margin: 10px 0 0;
		@include sp {
			font-size: 1.2rem;
		}
	}
}

.plant-movie {
	width: 100%;
	max-width: 932px;
	aspect-ratio: 16/9;
	margin: 0 auto;
	&__inner {
		width: 100%;
    height: 100%;
	}
}
